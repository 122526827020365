import { useEffect, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { PieceProvider } from './providers/piece.provider';
import { logEvent } from 'firebase/analytics';
import WithNavigation from './components/WithNavbar';
import SignIn from './components/views/SignIn';
import Confirm from './components/views/Confirm';
import PrivateRoute from './shared/PrivateRoute';
import RestrictedRoute from './shared/RestrictedRoute';
import PiecesList from './components/views/PiecesList';
import AddPiece from './components/views/AddPiece';
import EditPiece from './components/views/EditPiece';
import Practice from './components/views/Practice';
import Account from './components/views/Account';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, analytics } from './lib/firebase';
import ReactAppzi from 'react-appzi';
import theme from './theme';
import Privacy from './components/views/Privacy';
import Terms from './components/views/Terms';

function App() {
  const [user, loading] = useAuthState(auth);
  ReactAppzi.initialize('YgnDH');

  useEffect(() => {
    logEvent(analytics, 'session_start');
  }, []);

  useEffect(() => {
    if (user) logEvent(analytics, 'login');
  }, [user]);

  if (loading) return <div></div>;

  return (
    <>
      <PieceProvider>
        <Routes>
          <Route
            path='/'
            element={
              <RestrictedRoute>
                <SignIn />
              </RestrictedRoute>
            }
          />
          <Route
            path='/confirm'
            element={
              <RestrictedRoute>
                <Confirm />
              </RestrictedRoute>
            }
          />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
          <Route
            path='/signup'
            element={
              <RestrictedRoute>
                <SignIn />
              </RestrictedRoute>
            }
          />
          <Route
            path='/signin'
            element={
              <RestrictedRoute>
                <SignIn />
              </RestrictedRoute>
            }
          />
          <Route
            path='/pieces'
            element={
              <PrivateRoute>
                <WithNavigation>
                  <PiecesList />
                </WithNavigation>
              </PrivateRoute>
            }
          />
          <Route
            path='/new'
            element={
              <PrivateRoute>
                <WithNavigation>
                  <AddPiece />
                </WithNavigation>
              </PrivateRoute>
            }
          />
          <Route
            path='/pieces/:id/edit'
            element={
              <PrivateRoute>
                <WithNavigation>
                  <EditPiece />
                </WithNavigation>
              </PrivateRoute>
            }
          />
          <Route
            path='/practice'
            element={
              <PrivateRoute>
                <WithNavigation>
                  <Practice />
                </WithNavigation>
              </PrivateRoute>
            }
          />
          <Route
            path='/account'
            element={
              <PrivateRoute>
                <WithNavigation>
                  <Account />
                </WithNavigation>
              </PrivateRoute>
            }
          />
        </Routes>
        <Toaster
          toastOptions={{
            className: '',
            style: {
              fontSize: theme.fontSize.normal,
              padding: '1.4rem',
              color: theme.colors.blueDark,
              background: theme.colors.white,
            },
            success: {
              iconTheme: {
                primary: theme.colors.green,
                secondary: theme.colors.white,
              },
            },
            error: {
              iconTheme: {
                primary: theme.colors.red,
                secondary: theme.colors.white,
              },
            },
          }}
        />
      </PieceProvider>
    </>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback='loading'>
      <App />
    </Suspense>
  );
}
