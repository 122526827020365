import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  heading: string;
  font?: 'dark';
}

interface StyledProps {
  big?: boolean;
  font?: 'dark';
}

const StyledHeader = styled.header<StyledProps>`
  min-height: 9.5rem;
  display: flex;
  justify-content: center;
  color: ${(props) =>
    props.font === 'dark'
      ? props.theme.colors.blueDark
      : props.theme.colors.white};
  align-items: flex-end;
  padding: 0 2.5rem;
  line-height: 2.8rem;
  text-align: center;

  & h1 {
    font-size: ${(props) => props.theme.fontSize.big};
    font-weight: 600;
    margin-bottom: 2.5rem;
    padding-top: 4.2rem;
  }
`;

export default function Header({ heading, font }: Props): ReactElement {
  return (
    <StyledHeader font={font}>
      <h1>{heading}</h1>
    </StyledHeader>
  );
}
