const _precision = 5;
let _bpm = 0;
let _taps: number[] = [];

export const handleEvent = (): number => {
  _taps.push(Date.now());
  calcBPM();
  return Math.floor(_bpm);
};

function calcBPM(): number {
  let current_bpm = 0;
  let ticks = [];

  if (_taps.length >= 2) {
    for (let i = 0; i < _taps.length; i++) {
      if (i >= 1) {
        // calc bpm between last two taps
        ticks.push(
          Math.round((60 / (_taps[i] / 1000 - _taps[i - 1] / 1000)) * 100) / 100
        );
      }
    }
  }

  if (_taps.length >= 24) {
    _taps.shift();
  }

  if (ticks.length >= 2) {
    current_bpm = getAverage(ticks, _precision);

    _bpm = current_bpm;

    return _bpm;
  }

  return 0;
}

function getAverage(Values: number[], Precision: Number) {
  let ticks = Values;
  let n = 0;

  for (let i = ticks.length - 1; i >= 0; i--) {
    n += ticks[i];
    if (ticks.length - i >= Precision) break;
  }

  return n / _precision;
}

// init
window.onload = handleEvent;
