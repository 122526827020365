import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Stripe | null;

// Stripe.js will not be loaded until `loadStripe` is called
const initializeStripe = async () => {
  if (!stripePromise) {
    const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    stripePromise = await loadStripe(publishableKey as string);
  }

  return stripePromise;
};

export default initializeStripe;
