import { useEffect, useState } from 'react';
import { AuthContext } from '../contexts/auth.context';
import { User } from 'firebase/auth';
import { auth } from '../lib/firebase';

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isPro, setIsPro] = useState<boolean>(false);

  useEffect(() => {
    let unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      firebaseUser?.getIdToken(true);
      setUser(firebaseUser);

      async function isUserPro() {
        const decodedToken = await firebaseUser?.getIdTokenResult();
        setIsPro(decodedToken?.claims?.stripeRole ? true : false);
      }

      isUserPro();
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ user, isPro }}>
      {children}
    </AuthContext.Provider>
  );
};
