import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

export default function createLink() {
  const createCustomerPortalLink = httpsCallable(
    functions,
    'ext-firestore-stripe-payments-createPortalLink'
  );

  createCustomerPortalLink({
    returnUrl: `${window.location.origin}/account`,
  })
    .then((result: any) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      const data = result.data;
      console.log(data);
      window.location.assign(data.url);
    })
    .catch((err) => console.log(err));
}
