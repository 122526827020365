import styled from 'styled-components';
import { darken } from 'polished';

interface ButtonProps {
  marginTop?: boolean;
  marginBottom?: boolean;
  delete?: boolean;
}

const TextButton = styled.button<ButtonProps>`
  border: none;
  background-color: transparent;
  font-size: ${(props) => props.theme.fontSize.normal};
  font-weight: 600;
  text-decoration: underline;
  margin-top: ${(props) => (props.marginTop ? '3rem' : '1rem')};
  margin-bottom: ${(props) => props.marginBottom && '3rem'};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${(props) =>
      props.delete
        ? darken(0.08, props.theme.colors.red)
        : darken(0.08, props.theme.colors.white)};
  }
`;

export default TextButton;
