const defaultTheme = {
  colors: {
    red: '#CF6766',
    neutralLight: '#BBBBBB',
    white: '#ffffff',
    green: '#95BF74',
    blueDark: '#031424',
    blueLight: '#8EAEBD',
    blue: '#30415D',
  },
  fontSize: {
    huge: '4.8rem',
    big: '2.4rem',
    normal: '1.8rem',
    small: '1.4rem',
  },
  fontFamily: 'Nunito, Sans Serif',
};

export default defaultTheme;
