import styled from 'styled-components';
import { darken } from 'polished';

interface CtaButtonProps {
  outline?: boolean;
}

const CtaButton = styled.button<CtaButtonProps>`
  border: ${(props) =>
    props.outline ? `2px solid ${props.theme.colors.red}` : 'none'};
  border-radius: 8px;
  cursor: pointer;
  color: ${(props) => (props.outline ? props.theme.colors.red : 'black')};
  font-size: ${(props) => props.theme.fontSize.normal};
  font-weight: 600;
  background: ${(props) =>
    props.outline ? 'transparent' : props.theme.colors.red};
  height: 6rem;
  width: 100%;
  transition: all 0.15s ease-in-out;
  margin-bottom: 1.5rem;
  position: relative;

  &:hover {
    background: ${(props) =>
      props.outline
        ? darken(0.08, props.theme.colors.blue)
        : darken(0.08, props.theme.colors.red)};

    color: ${(props) => props.outline && props.theme.colors.red};
  }

  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &:disabled {
    cursor: default;
    background: ${(props) =>
      props.outline
        ? darken(0.1, props.theme.colors.blue)
        : darken(0.3, props.theme.colors.red)};

    &:hover {
    }
  }
`;

export default CtaButton;
