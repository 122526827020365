import styled from 'styled-components';
import guitarPlayer from '../assets/images/guitarplayer.png';
import { device } from '../shared/breakpoints';
import Div100vh from 'react-div-100vh';

export interface WrapperProps {
  children: React.ReactNode;
  isSignUpOrIn?: boolean;
  bg?: 'dark' | 'white';
  hasImage?: boolean;
  bgImage?: string;
  marginBottom?: boolean;
}

const Box = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin-bottom: ${(props) =>
    props.marginBottom
      ? 'calc(env(safe-area-inset-bottom) + 4.5rem)'
      : 'env(safe-area-inset-bottom)'};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`;

const StyledWrapper = styled.div<WrapperProps>`
  background: ${(props) =>
    props.bg === 'dark'
      ? props.theme.colors.blueDark
      : props.theme.colors.white};
  background-image: ${(props) =>
    props.hasImage ? `url(${props.bgImage})` : 'none'};
  background-repeat: no-repeat;
  background-size: 260px auto;
  background-position: ${(props) =>
    props.isSignUpOrIn ? 'bottom 16rem left 2.7rem' : 'center 25vh'};
  box-shadow: ${(props) =>
    props.isSignUpOrIn ? 'inset 0 0 0 100vw rgba(255, 255, 255, 0.6)' : 'none'};
  min-height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow-x: hidden;

  @media ${device.laptop} {
    background-position: ${(props) =>
      props.isSignUpOrIn
        ? 'left calc(50% - 24.3rem) bottom 16rem'
        : 'center 25vh'};
  }
`;

function Wrapper({
  children,
  isSignUpOrIn,
  bg,
  hasImage,
  marginBottom,
}: WrapperProps): React.ReactElement {
  return (
    <Div100vh>
      <StyledWrapper
        isSignUpOrIn={isSignUpOrIn}
        bg={bg}
        hasImage={hasImage}
        bgImage={guitarPlayer}
      >
        <Box marginBottom={marginBottom}>{children}</Box>
      </StyledWrapper>
    </Div100vh>
  );
}

export default Wrapper;
