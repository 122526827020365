import { query, collection, setDoc, doc } from '@firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { auth, db } from '../lib/firebase';
import { PieceContext, Pieces } from '../contexts/piece.context';
import { PartContext, ExtendedPart } from '../contexts/part.context';
import practicePriority from '../lib/practicePriority';
import { isDateSameDay } from '../lib/isDateSameDay';
import partsByPriority from '../lib/partsByPriority';

export const PieceProvider: React.FC = ({ children }) => {
  const [pieces] = useCollectionData(
    query(collection(db, `/users/${auth.currentUser?.uid}/pieces`)),
    { idField: '_id' }
  );

  let parts: Array<ExtendedPart> | undefined = undefined;

  if (auth.currentUser && pieces) {
    parts = [];

    auth.currentUser.getIdTokenResult().then((data) => {
      if (!isDateSameDay(new Date(data.issuedAtTime), new Date())) {
        pieces.forEach(async (piece) => {
          piece.parts.forEach((part: ExtendedPart) => {
            const { endTempo, importance } = piece;
            const { currentTempo, lastPracticed } = part;
            part.priority = practicePriority(
              endTempo,
              currentTempo,
              importance,
              lastPracticed
            );
          });
          await setDoc(
            doc(db, `/users/${auth.currentUser?.uid}/pieces`, piece._id),
            piece
          );
        });
      }
    });

    pieces.forEach((piece) => {
      const { endTempo, importance } = piece;
      piece.parts.forEach((part: ExtendedPart) => {
        parts?.push({
          ...part,
          endTempo,
          importance,
          pieceId: piece._id,
          piece: piece.name,
        });
      });
    });

    parts = partsByPriority(parts);
  }

  return (
    <PieceContext.Provider value={pieces as unknown as Pieces}>
      <PartContext.Provider value={parts}>{children}</PartContext.Provider>
    </PieceContext.Provider>
  );
};
