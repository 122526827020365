import styled from 'styled-components';

interface CardProps {
  background?: 'transparent' | 'neutral';
  fontColor?: 'black' | 'white';
  accent?: boolean;
  paddingTop?: boolean;
}

const Card = styled.div<CardProps>`
  min-height: 8rem;
  width: 100%;
  border-radius: 8px;
  background: ${(props) => {
    if (props.background === 'transparent') return 'transparent';
    else if (props.background === 'neutral')
      return props.theme.colors.neutralDark;
  }};
  padding: 0 2rem 1rem 2rem;
  padding-top: ${(props) => (props.paddingTop ? '2rem' : 0)};
  box-sizing: border-box;
  color: ${(props) => {
    if (props.fontColor === 'black') return props.theme.colors.blueDark;
    else if (props.fontColor === 'white') return props.theme.colors.white;
  }};

  .current-plan {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: ${(props) => props.theme.fontSize.big};
    font-weight: 600;
  }

  .benefits {
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: 600;

    & ul {
      margin-top: 1.5rem;
      list-style: none;
    }

    & li {
      display: flex;
      align-items: center;

      & svg {
        font-size: 2.4rem;
        color: ${(props) =>
          props.accent ? props.theme.colors.red : props.theme.colors.white};
        margin-right: 1rem;
      }
    }
  }
`;

export default Card;
