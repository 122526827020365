import styled from 'styled-components';

interface Props {
  dark?: boolean;
}

const StyledFrom = styled.form<Props>`
  z-index: 9999;
  & input {
    display: block;
    height: 4.8rem;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid
      ${(props) =>
        props.dark ? props.theme.colors.blue : props.theme.colors.white};
    outline: none;
    border-radius: 8px;
    width: 100%;
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSize.normal};
    padding-left: 1.2rem;
    transition: all 0.15s ease-in-out;
    box-sizing: border-box;

    &::placeholder {
      color: ${(props) => props.theme.colors.neutralLight};
    }

    &:focus {
      border: 2px solid ${(props) => props.theme.colors.blue};
    }

    &:invalid {
      border: 2px solid ${(props) => props.theme.colors.red};
    }
  }

  & label {
    display: block;
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.colors.white};
    font-weight: 400;
    margin-bottom: 8px;

    &:not(:first-of-type) {
      margin-top: 1.8rem;
    }

    & .required {
      color: ${(props) => props.theme.colors.red};
    }
  }

  & .error {
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.colors.red};
    display: block;
    margin-top: 5px;
  }
`;

export default StyledFrom;
