import { User } from '@firebase/auth';
import { useState, useEffect } from 'react';
import isUserPro from './isUserPro';

export default function useProStatus(user: User) {
  const [proStatus, setProStatus] = useState<boolean | null>(null);

  useEffect(() => {
    if (user) {
      const checkProStatus = async function () {
        setProStatus(await isUserPro());
      };
      checkProStatus();
    }
  }, [user]);

  return proStatus;
}
