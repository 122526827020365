import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';
import { device } from './breakpoints';


export const bodyStyles = css`
  font-size: 1.8rem;
  color: ${props => props.theme.colors.blueDark};
  flex-grow: 1;
  width: 100%;
  background: ${props => props.theme.colors.blueDark};
`;

export const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    display: flex;
    flex-direction: column;

    font-size: 62.5%; // 1rem = 10px

    /* @media ${device.mobileM} {
      font-size: 56.25%; // 1rem = 9px
    } */

    @media ${device.mobileS} {
      font-size: 50%; // 1rem = 8px
    }

    @media ${device.laptopL} {
      font-size:  68.75%
    }
  }

  *:not(i){
    font-family: Nunito;
  }

  body {
    ${bodyStyles}
  }

  a {
    text-decoration: none;
  }

.ReactModal__Body--open {
    overflow: hidden;
}
`;