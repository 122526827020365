import React, { ReactElement, useContext, useState } from 'react';
import styled from 'styled-components';
import { serverTimestamp, addDoc, collection } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { transparentize } from 'polished';
import { toast } from 'react-hot-toast';
import { nanoid } from 'nanoid';
import { auth, db, analytics } from '../lib/firebase';
import { darken } from 'polished';
import { handleEvent } from '../lib/tapTempo';
import practicePriority from '../lib/practicePriority';
import { AuthContext } from '../contexts/auth.context';
import { PieceContext } from '../contexts/piece.context';
import * as Constants from '../shared/constants';
import useProStatus from '../lib/stripe/useProStatus';
import { useTranslation } from 'react-i18next';

interface Props {
  showUpgradeDialog: (variant: 'parts' | 'pieces') => void;
  cancel: () => void;
}

type Inputs = {
  name: string;
  part: string;
  currentTempo: string;
  endTempo: string;
  importance: string;
  switch: string;
};

const WholePieceWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2.5rem;
  flex: 1;
  flex-direction: column;
  margin-top: 6rem;
`;

const InputGroup = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  & .label {
    display: inline-block;
    margin-bottom: 1.4rem;
  }

  & .input-with-button {
    display: flex;
  }

  & .input {
    border: 1px solid ${(props) => props.theme.colors.white};
    border-radius: 8px;
    height: 5rem;
    background: transparent;
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSize.normal};
    padding: 12px;
    width: 100%;

    &::placeholder {
      color: ${(props) => props.theme.colors.blue};
    }

    &:focus {
      /* background: ${(props) => props.theme.colors.blue}; */
      border: 1px solid ${(props) => props.theme.colors.blueLight};
      &-visible {
        outline: none;
      }
    }

    &:invalid {
      border: 1px solid ${(props) => props.theme.colors.red};
    }

    &--button {
      display: inline;
      flex: 1;
      border-radius: 8px 0 0 8px;
    }
  }

  & .error {
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.colors.red};
    display: block;
    position: absolute;
    margin-top: 0.7rem;
  }

  & .options {
    display: flex;
    justify-content: space-between;
  }

  & .legend {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: ${(props) => props.theme.fontSize.small};
    margin-top: 1rem;
  }

  [type='checkbox']:checked,
  [type='checkbox']:not(:checked),
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .importance:checked + label,
  .importance:not(:checked) + label {
    position: relative;
    width: 5rem;
    height: 5rem;
    font-size: ${(props) => props.theme.fontSize.normal};
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};
    transition: all 0.15s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.colors.blue};
  }
  .importance:not(:checked) + label {
    background: ${(props) => props.theme.colors.blue};
  }
  .importance:checked + label {
    background: ${(props) => props.theme.colors.blueLight};
  }
  /* .importance:not(:checked) + label:hover {
  } */

  & .tap-tempo {
    height: 5rem;
    padding: 0 1.5rem;
    margin-left: auto;
    margin-right: 0;
    display: inline;
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blue};
    border: none;
    border-radius: 0 8px 8px 0;
    border: 1px solid ${(props) => props.theme.colors.white};
    border-left: 0;
    transition: background 0.15s ease-in-out;

    &:hover {
      background: ${(props) => darken(0.08, props.theme.colors.blue)};
      cursor: pointer;
    }

    &:active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }
`;

const Navigation = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  color: ${(props) => props.theme.colors.white};
  margin-top: 7rem;

  & .nav-item {
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSize.big};
    font-weight: 600;
    padding: 0;
    background-color: transparent;
    border: none;
    width: min-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
  }

  & .back {
    margin-left: 1rem;
  }

  & .next {
    margin-right: 1rem;
  }

  & .disabled {
    color: ${(props) => transparentize(0.7, props.theme.colors.white)};
    cursor: default;
  }

  & svg {
    font-size: 3rem;
  }
`;

export default function AddWholePiece({
  showUpgradeDialog,
  cancel,
}: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const isPro = useProStatus(user);
  const pieces = useContext(PieceContext);
  const [endTempoBpm, setEndTempoBpm] = useState<number | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentTempoBpm, setCurrentBpm] = useState<number | undefined>(
    undefined
  );

  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const hasNext = () => {
    return currentStep < 3;
  };

  const hasPrev = () => {
    return currentStep > 0;
  };

  const prev = () => {
    if (hasPrev()) {
      setCurrentStep(currentStep - 1);
    }
  };

  const next = () => {
    if (hasNext()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleEndTempoTap = () => {
    setEndTempoBpm(handleEvent());
    if (endTempoBpm)
      setValue('endTempo', `${endTempoBpm}`, { shouldValidate: true });
  };

  const handleCurrentTempoTap = () => {
    setCurrentBpm(handleEvent());
    if (currentTempoBpm)
      setValue('currentTempo', `${currentTempoBpm}`, { shouldValidate: true });
  };

  const defaultValues = {
    importance: '3',
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const tooManyPieces =
      pieces && pieces.length >= Constants.MAX_PIECES_ALLOWED;
    if (!isPro && tooManyPieces) {
      showUpgradeDialog('pieces');
      return;
    }

    try {
      if (auth.currentUser) {
        const currentTempo = parseInt(data.currentTempo);
        const endTempo = parseInt(data.endTempo);
        const importance = parseInt(data.importance);
        const createdAt = serverTimestamp();
        const lastPracticed = new Date();
        const userId = auth.currentUser.uid;
        const updatedAt = serverTimestamp();

        const newPiece = {
          ...data,
          endTempo,
          importance,
          createdAt,
          userId,
          updatedAt,
          parts: [
            {
              id: nanoid(),
              currentTempo,
              lastPracticed,
              priority: practicePriority(
                endTempo,
                currentTempo,
                importance,
                new Date()
              ),
            },
          ],
        };

        toast.promise(
          addDoc(
            collection(db, `/users/${auth.currentUser.uid}/pieces`),
            newPiece
          ),
          {
            loading: t('toast.loading'),
            success: t('toast.saveSuccess', { name: newPiece.name }),
            error: t('toast.error'),
          }
        );
        logEvent(analytics, 'add_piece');
        navigate('/pieces');
      }
    } catch (error: any) {
      switch (error.code) {
        default:
          toast.error(t('toast.error'));
      }
    }
  };

  return (
    <WholePieceWrapper>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
        id='add-new-piece'
      >
        {currentStep === 0 && (
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('addPiece.whatsName')}
            </label>
            <input
              type='text'
              className='input'
              autoFocus
              placeholder={t('form.typeYourAnswerHere')}
              {...register('name', {
                required: { value: true, message: t('form.fieldRequired') },
                maxLength: {
                  value: 25,
                  message: t('form.pieceMaxLength'),
                },
                minLength: {
                  value: 3,
                  message: t('form.pieceMinLength'),
                },
              })}
            />
            {errors.name?.message && (
              <span className='error'>{errors.name.message}</span>
            )}
          </InputGroup>
        )}
        {currentStep === 1 && (
          <InputGroup>
            <p className='label'>{t('addPiece.howUrgent')}</p>
            <div className='options'>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='1'
                id='importance-1'
              />
              <label className='for-importance' htmlFor='importance-1'>
                1
              </label>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='2'
                id='importance-2'
              />
              <label className='for-importance' htmlFor='importance-2'>
                2
              </label>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='3'
                id='importance-3'
              />
              <label className='for-importance' htmlFor='importance-3'>
                3
              </label>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='4'
                id='importance-4'
              />
              <label className='for-importance' htmlFor='importance-4'>
                4
              </label>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='5'
                id='importance-5'
              />
              <label className='for-importance' htmlFor='importance-5'>
                5
              </label>
            </div>
            <div className='legend'>
              <div>{t('piece.notUrgent')}</div>
              <div>{t('piece.veryUrgent')}</div>
            </div>
          </InputGroup>
        )}
        {currentStep === 2 && (
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('addPiece.whatGoalBpm')}
            </label>
            <div className='input-with-button'>
              <input
                type='number'
                className='input input--button'
                placeholder={t('form.typeYourAnswerHere')}
                {...register('endTempo', {
                  required: {
                    value: true,
                    message: t('form.fieldRequired'),
                  },
                  min: { value: 40, message: t('form.bpmMin') },
                  max: { value: 208, message: t('form.bpmMax') },
                })}
              />
              <button
                className='tap-tempo'
                onClick={handleEndTempoTap}
                type='button'
              >
                Tap Tempo
              </button>
            </div>
            {errors.endTempo?.message && (
              <span className='error'>{errors.endTempo.message}</span>
            )}
          </InputGroup>
        )}
        {currentStep === 3 && (
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('addPiece.whatCurrentBpm')}
            </label>
            <div className='input-with-button'>
              <input
                type='number'
                className='input input--button'
                placeholder={t('form.typeYourAnswerHere')}
                {...register('currentTempo', {
                  required: {
                    value: true,
                    message: t('form.fieldRequired'),
                  },
                  min: { value: 40, message: t('form.bpmMin') },
                  max: { value: 208, message: t('form.bpmMax') },
                })}
              />
              <button
                className='tap-tempo'
                onClick={handleCurrentTempoTap}
                type='button'
              >
                Tap Tempo
              </button>
            </div>
            {errors.currentTempo?.message && (
              <span className='error'>{errors.currentTempo.message}</span>
            )}
          </InputGroup>
        )}
      </form>
      <Navigation>
        <button
          className='nav-item'
          onClick={hasPrev() ? prev : cancel}
          disabled={!hasPrev}
          type='button'
        >
          <IoChevronBack />
        </button>
        {hasNext() && (
          <button
            className={`nav-item ${!hasNext() || !isValid ? 'disabled' : ''}`}
            type='button'
            onClick={hasNext() ? next : undefined}
            disabled={!hasNext || !isValid}
          >
            <span className='next'>{t('dialog.next')}</span>
            <IoChevronForward />
          </button>
        )}
        {!hasNext() && (
          <button
            className={`nav-item ${!isValid ? 'disabled' : ''}`}
            type='submit'
            form='add-new-piece'
            disabled={!hasNext || !isValid}
          >
            <span className='next'>{t('dialog.save')}</span>
            <IoChevronForward />
          </button>
        )}
      </Navigation>
    </WholePieceWrapper>
  );
}
