import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import { Piece } from '../contexts/piece.context';

interface StyledPieceCardProps {
  percentOfEndTempo: number;
}

const StyledPieceCard = styled.div<StyledPieceCardProps>`
  min-height: 8rem;
  width: 100%;
  border-radius: 8px;
  background: ${(props) => {
    if (props.percentOfEndTempo < 60) return props.theme.colors.red;
    else if (props.percentOfEndTempo < 85) return props.theme.colors.blue;
    else return props.theme.colors.green;
  }};
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .content {
    display: flex;
    height: 100%;
    min-height: 5rem;
    flex-direction: column;
    justify-content: space-between;
  }

  .piece {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    margin: 0 1.5rem 1rem 0;

    &__name {
      font-size: ${(props) => props.theme.fontSize.normal};
    }

    &__part {
      font-size: ${(props) => props.theme.fontSize.small};
    }
  }

  .tempo {
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: 400;
  }

  .edit {
    font-size: 2.1rem;
    color: rgba(255, 255, 255, 0.8);
  }
`;

export interface PieceCardProps {
  piece: Piece;
}

export default function PieceCard({ piece }: PieceCardProps): ReactElement {
  const percentOfEndTempo = (function () {
    let combinedCurrentTempo = 0;

    piece.parts.forEach((part) => {
      combinedCurrentTempo += part.currentTempo;
    });

    const averageTempo = combinedCurrentTempo / piece.parts.length;

    return (averageTempo * 100) / piece.endTempo;
  })();

  return (
    <StyledPieceCard percentOfEndTempo={percentOfEndTempo}>
      <div className='content'>
        <div className='piece'>
          <span className='piece__name'>{piece.name}</span>
        </div>
        <div className='tempo'>{piece.endTempo} bpm</div>
      </div>
      <Link to={`/pieces/${piece._id}/edit`}>
        <div className='edit'>
          <MdEdit />
        </div>
      </Link>
    </StyledPieceCard>
  );
}
