import { rgba } from 'polished';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import Button from './Button';

interface Props {
  text: string;
  action?: () => void;
  isOpen: boolean;
  setOpen: (arg0: boolean) => void;
  actionLabel?: string;
}

const styles = {
  overlay: {
    background: rgba(0, 0, 0, 0.7),
    zIndex: 9999,
  },
  content: {
    background: '#30415d',
    border: 'none',
    color: '#FFFFFF',
    width: '30rem',
    maxHeight: 'fit-content',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
  },
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: 'center';
  height: 100%;
  justify-content: space-between;

  & .text {
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.normal};
  }

  & .button-box {
    display: flex;
    justify-content: space-between;
  }
`;

ReactModal.setAppElement('#root');

export default function DialogModal({
  text,
  action,
  actionLabel = 'Submit',
  isOpen,
  setOpen,
}: Props): ReactElement {
  const { t } = useTranslation();

  const handleConfirm = () => {
    if (action) {
      action();
    }
    setOpen(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      contentLabel='Dialog Modal'
      style={styles}
    >
      <Content>
        <div className='text'>{text}</div>
        <div className='button-box'>
          {action && (
            <Button
              variant={
                actionLabel !== t('account.logout') ? 'blueLight' : 'red'
              }
              size='sm'
              onClick={handleConfirm}
              label={actionLabel}
            />
          )}
          <Button
            variant='neutral'
            size='sm'
            onClick={() => setOpen(false)}
            label={t('dialog.cancel')}
            type='button'
          />
        </div>
      </Content>
    </ReactModal>
  );
}
