import styled, { keyframes } from 'styled-components';

const ldsEllipsis1 = keyframes`  
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ldsEllipsis3 = keyframes`  
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const ldsEllipsis2 = keyframes`  
  0% {
    transform: translate(0,0);
  }
  100% {
    transform: translate(24px,0);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
`;

const StyledLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  & div {
    position: absolute;
    top: 3.3rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.white};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1) {
    left: 8px;
    animation: ${ldsEllipsis1} 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 8px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }
  & div:nth-child(3) {
    left: 32px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }
  & div:nth-child(4) {
    left: 56px;
    animation: ${ldsEllipsis3} 0.6s infinite;
  }
`;

type LoaderProps = {
  show: boolean;
};

export default function Loader({ show }: LoaderProps) {
  return show ? (
    <Wrapper>
      <StyledLoader>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </StyledLoader>
    </Wrapper>
  ) : null;
}
