import React from 'react';
import { ExtendedPart } from '../contexts/part.context';
import styled from 'styled-components';
import Header from './Header';
import ContentCard from './ContentCard';
import { IoChevronForward } from 'react-icons/io5';
import Wrapper from './Wrapper';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';

interface Props {
  parts: Array<ExtendedPart> | undefined;
  start: () => void;
}

interface PartProps {
  priority: number;
}

const Part = styled.div<PartProps>`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => {
    if (props.priority < 3.5) return props.theme.colors.green;
    else if (props.priority < 6) return props.theme.colors.blue;
    else return props.theme.colors.red;
  }};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & .part {
    font-size: ${(props) => props.theme.fontSize.small};
  }
`;

const Start = styled.button`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize.big};
  font-weight: 600;
  padding: 0;
  background-color: transparent;
  border: none;
  width: min-content;
  cursor: pointer;
  text-decoration: underline;
  user-select: none;
  margin-left: auto;
  margin-top: 3rem;
  display: flex;
  align-items: center;

  & svg {
    font-size: 3rem;
    margin-left: 1rem;
  }
`;

export default function DailyRoutine({ parts, start }: Props) {
  const { t } = useTranslation();

  if (!parts) {
    return <Loader show />;
  }

  const partCards = parts.map((part) => (
    <Part priority={part.priority} key={part.id}>
      <div className='piece'>{part.piece}</div>
      {part.name && <div className='part'>({part.name})</div>}
    </Part>
  ));

  return (
    <Wrapper bg='dark'>
      <Header heading={t('dailyRoutine.heading')} />
      <ContentCard variant='transparent' noPaddingTop>
        {partCards}
        <Start onClick={start}>
          {t('dailyRoutine.practice')} <IoChevronForward />
        </Start>
      </ContentCard>
    </Wrapper>
  );
}
