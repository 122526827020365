import { ReactElement, useState } from 'react';
import Header from '../Header';
import Wrapper from '../Wrapper';
import AddWholePiece from '../AddPieceAsWhole';
import ChoosePieceType from '../ChoosePieceType';
import AddPieceAsParts from '../AddPieceAsParts';
import UpgradeDialog from '../UpgradeDialog';
import { useTranslation } from 'react-i18next';

export default function AddPiece(): ReactElement {
  const { t } = useTranslation();
  const [pieceType, setPieceType] = useState<'whole' | 'parts' | undefined>(
    undefined
  );
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [variant, setVariant] = useState<'parts' | 'pieces'>('pieces');

  const handleUpgradeDialog = (variant: 'parts' | 'pieces') => {
    setVariant(variant);
    setShowUpgradeDialog(true);
  };

  return (
    <>
      <Wrapper bg='dark' marginBottom>
        <Header heading={t('addPiece.addNewPiece')} />
        {pieceType === undefined && (
          <ChoosePieceType choosePieceType={setPieceType} />
        )}
        {pieceType === 'whole' && (
          <AddWholePiece
            showUpgradeDialog={handleUpgradeDialog}
            cancel={() => setPieceType(undefined)}
          />
        )}
        {pieceType === 'parts' && (
          <AddPieceAsParts
            showUpgradeDialog={handleUpgradeDialog}
            cancel={() => setPieceType(undefined)}
          />
        )}
      </Wrapper>
      {showUpgradeDialog && (
        <UpgradeDialog
          close={() => setShowUpgradeDialog(false)}
          variant={variant}
        />
      )}
    </>
  );
}
