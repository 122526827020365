import { Timestamp } from '@firebase/firestore';
import dateDiffInDays from './dateDiffInDays';

// importance of difference in tempo between 0.1 and 1 ( lower means higher importance )
const k1 = 0.4;
// importance of days since last practiced between 0.1 and 1 ( higher means higher importance )
const k2 = 0.5;
// current Date
const dateCurrent = new Date();

function practicePriority(
  endTempo: number,
  currentTempo: number,
  importance: number,
  lastPracticed: Timestamp | Date
) {
  let lastPracticedAsDate;
  if (lastPracticed instanceof Timestamp) {
    lastPracticedAsDate = lastPracticed.toDate();
  } else {
    lastPracticedAsDate = lastPracticed;
  }

  const tempoFactor = (endTempo - currentTempo) / (k1 * currentTempo);
  const timeFactor =
    (dateDiffInDays(lastPracticedAsDate, dateCurrent) * importance * k2) / 10;
  const priority = importance + tempoFactor + timeFactor;
  return priority;
}

export default practicePriority;
