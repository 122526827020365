import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { MdEdit } from 'react-icons/md';
import { Part } from '../contexts/part.context';
import { IoTrash } from 'react-icons/io5';

interface Props {
  part: Part;
  deletePart: (id: string) => void;
  editPart: (part: Part) => void;
}

const StyledPartCard = styled.div`
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.blue};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  & .actions {
    display: flex;
    font-size: 2.1rem;
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      cursor: pointer;
    }

    &__delete {
      color: ${(props) => props.theme.colors.red};
      margin-left: 2.5rem;
    }
  }
`;

export default function PartCard({
  part,
  deletePart,
  editPart,
}: Props): ReactElement {
  return (
    <StyledPartCard>
      <div className='part-name'>{part.name}</div>
      <div className='actions'>
        <div className='actions__edit'>
          <MdEdit onClick={() => editPart(part)} />
        </div>
        <div className='actions__delete' onClick={() => deletePart(part.id)}>
          <IoTrash />
        </div>
      </div>
    </StyledPartCard>
  );
}
