import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { ReactComponent as WholePieceIcon } from '../assets/images/whole.svg';
import { ReactComponent as SectionsPieceIcon } from '../assets/images/sections.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  choosePieceType: Dispatch<SetStateAction<'whole' | 'parts' | undefined>>;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2.5rem;
  flex: 1;
  flex-direction: column;
  margin-top: 6rem;
`;

const ChoosePieceButton = styled.button`
  height: 16rem;
  background: transparent;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 2.5rem;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  transition: background 0.15s ease-in-out;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  &:hover {
    cursor: pointer;
    background: ${(props) => rgba(props.theme.colors.blueLight, 0.15)};
  }

  & .content {
    margin-left: 2.5rem;

    &__title {
      font-weight: 600;
      font-size: 1.8rem;
      text-align: left;
      margin-bottom: 1.2rem;
    }
    &__info {
      font-size: ${(props) => props.theme.fontSize.small};
      line-height: 1.8rem;
      text-align: left;
    }
  }
`;

export default function ChoosePieceType({
  choosePieceType,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ChoosePieceButton onClick={() => choosePieceType('whole')}>
        <div className='icon'>
          <WholePieceIcon />
        </div>
        <div className='content'>
          <div className='content__title'>{t('piece.wholePiece')}</div>
          <div className='content__info'>{t('piece.wholePieceText')}</div>
        </div>
      </ChoosePieceButton>
      <ChoosePieceButton onClick={() => choosePieceType('parts')}>
        <div className='icon'>
          <SectionsPieceIcon />
        </div>
        <div className='content'>
          <div className='content__title'>{t('piece.splitInSections')}</div>
          <div className='content__info'>{t('piece.splitInSectionsText')}</div>
        </div>
      </ChoosePieceButton>
    </Wrapper>
  );
}
