import styled from 'styled-components';
import { rgba } from 'polished';
import { device } from '../shared/breakpoints';

interface CardProps {
  children: React.ReactNode;
  isSignUpOrIn?: boolean;
  variant?: 'signUp' | 'practice' | 'transparent' | 'opacity';
  noPaddingTop?: boolean;
  font?: 'dark';
}

const StyledCard = styled.section<CardProps>`
  background: ${(props) => {
    if (props.variant === 'transparent') return 'transparent';
    else if (props.variant === 'opacity')
      return rgba(props.theme.colors.blue, 0.6);
    else return props.theme.colors.blue;
  }};
  color: ${(props) =>
    props.font === 'dark'
      ? props.theme.colors.blueDark
      : props.theme.colors.white};
  padding: 5rem 2.5rem 7rem 2.5rem;
  position: relative;
  margin-bottom: env(safe-area-inset-bottom);
  padding-top: ${(props) => (props.noPaddingTop ? 0 : '5rem')};
  border-radius: 30px 30px 0 0;
  display: flex;
  flex-direction: column;
  flex-grow: ${(props) => (props.isSignUpOrIn ? '' : '1')};
  width: 100%;
  overflow-x: hidden;
  max-width: 800px;

  @media ${device.laptop} {
    padding: 5rem 6rem 5.5rem 6rem;
    padding-top: ${(props) => (props.noPaddingTop ? 0 : '5rem')};
  }
`;

export default function ContentCard({
  children,
  isSignUpOrIn,
  variant,
  noPaddingTop,
  font,
}: CardProps): React.ReactElement {
  return (
    <StyledCard
      isSignUpOrIn={isSignUpOrIn}
      variant={variant}
      noPaddingTop={noPaddingTop}
      font={font}
    >
      {children}
    </StyledCard>
  );
}
