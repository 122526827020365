import React, { ReactElement, useState, useContext } from 'react';
import styled from 'styled-components';
import { serverTimestamp, addDoc, collection } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { transparentize } from 'polished';
import { toast } from 'react-hot-toast';
import { auth, db, analytics } from '../lib/firebase';
import { handleEvent } from '../lib/tapTempo';
import practicePriority from '../lib/practicePriority';
import InputGroup from './styled/InputGroup';
import AddParts from './AddParts';
import { Part } from '../contexts/part.context';
import { PieceContext } from '../contexts/piece.context';
import { AuthContext } from '../contexts/auth.context';
import * as Constants from '../shared/constants';
import useProStatus from '../lib/stripe/useProStatus';
import { useTranslation } from 'react-i18next';

interface Props {
  showUpgradeDialog: (variant: 'pieces' | 'parts') => void;
  cancel: () => void;
}

type Inputs = {
  name: string;
  part: string;
  currentTempo: string;
  endTempo: string;
  importance: string;
  switch: string;
};

const PartsPieceWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2.5rem;
  flex: 1;
  flex-direction: column;
  margin-top: 6rem;
`;

const Navigation = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  color: ${(props) => props.theme.colors.white};
  margin-top: 7rem;

  & .nav-item {
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSize.big};
    font-weight: 600;
    padding: 0;
    background-color: transparent;
    border: none;
    width: min-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
  }

  & .back {
    margin-left: 1rem;
  }

  & .next {
    margin-right: 1rem;
  }

  & .disabled {
    color: ${(props) => transparentize(0.7, props.theme.colors.white)};
    cursor: default;
  }

  & svg {
    font-size: 3rem;
  }
`;

export default function AddPieceAsParts({
  showUpgradeDialog,
  cancel,
}: Props): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [endTempoBpm, setEndTempoBpm] = useState<number | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState(0);
  const { user } = useContext(AuthContext);
  const isPro = useProStatus(user);
  const pieces = useContext(PieceContext);
  const [parts, setParts] = useState<Array<Part>>([]);

  const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  const hasNext = () => {
    return currentStep < 3;
  };

  const hasPrev = () => {
    return currentStep > 0;
  };

  const prev = () => {
    if (hasPrev()) {
      setCurrentStep(currentStep - 1);
    }
  };

  const next = () => {
    if (hasNext()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleEndTempoTap = () => {
    setEndTempoBpm(handleEvent());
    if (endTempoBpm)
      setValue('endTempo', `${endTempoBpm}`, { shouldValidate: true });
  };

  const defaultValues = {
    importance: '3',
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const tooManyPieces =
      pieces && pieces.length >= Constants.MAX_PIECES_ALLOWED;
    const tooManyParts = parts.length > Constants.MAX_PARTS_ALLOWED;

    if (!isPro && tooManyPieces) {
      showUpgradeDialog('pieces');
      return;
    } else if (!isPro && tooManyParts) {
      showUpgradeDialog('parts');
      return;
    }

    try {
      if (auth.currentUser) {
        const currentTempo = parseInt(data.currentTempo);
        const endTempo = parseInt(data.endTempo);
        const importance = parseInt(data.importance);
        const createdAt = serverTimestamp();
        const lastPracticed = serverTimestamp();
        const userId = auth.currentUser.uid;
        const updatedAt = serverTimestamp();
        const partsWithPriority = parts.map((part) => {
          const priority = practicePriority(
            endTempo,
            part.currentTempo,
            importance,
            new Date()
          );
          return { ...part, priority };
        });

        const newPiece = {
          ...data,
          currentTempo,
          endTempo,
          importance,
          createdAt,
          lastPracticed,
          userId,
          updatedAt,
          parts: partsWithPriority,
          priority: practicePriority(
            endTempo,
            currentTempo,
            importance,
            new Date()
          ),
        };

        toast.promise(
          addDoc(
            collection(db, `/users/${auth.currentUser.uid}/pieces`),
            newPiece
          ),
          {
            loading: t('toast.loading'),
            success: t('toast.saveSuccess', { name: newPiece.name }),
            error: t('toast.error'),
          }
        );
        logEvent(analytics, 'add_piece');
        navigate('/pieces');
      }
    } catch (error: any) {
      switch (error.code) {
        default:
          toast.error(t('toast.error'));
      }
    }
  };

  return (
    <PartsPieceWrapper>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
        id='add-new-piece'
      >
        {currentStep === 0 && (
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('addPiece.whatsName')}
            </label>
            <input
              type='text'
              className='input'
              autoFocus
              placeholder={t('form.typeYourAnswerHere')}
              {...register('name', {
                required: { value: true, message: t('form.fieldRequired') },
                maxLength: {
                  value: 25,
                  message: t('form.pieceMaxLength'),
                },
                minLength: {
                  value: 3,
                  message: t('form.pieceMinLength'),
                },
              })}
            />
            {errors.name?.message && (
              <span className='error'>{errors.name.message}</span>
            )}
          </InputGroup>
        )}
        {currentStep === 1 && (
          <InputGroup>
            <p className='label'>{t('addPiece.howUrgent')}</p>
            <div className='options'>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='1'
                id='importance-1'
              />
              <label className='for-importance' htmlFor='importance-1'>
                1
              </label>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='2'
                id='importance-2'
              />
              <label className='for-importance' htmlFor='importance-2'>
                2
              </label>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='3'
                id='importance-3'
              />
              <label className='for-importance' htmlFor='importance-3'>
                3
              </label>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='4'
                id='importance-4'
              />
              <label className='for-importance' htmlFor='importance-4'>
                4
              </label>
              <input
                className='importance'
                {...register('importance')}
                type='radio'
                name='importance'
                value='5'
                id='importance-5'
              />
              <label className='for-importance' htmlFor='importance-5'>
                5
              </label>
            </div>
            <div className='legend'>
              <div>{t('piece.notUrgent')}</div>
              <div>{t('piece.veryUrgent')}</div>
            </div>
          </InputGroup>
        )}
        {currentStep === 2 && (
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('addPiece.whatGoalBpm')}
            </label>
            <div className='input-with-button'>
              <input
                type='number'
                className='input input--button'
                placeholder={t('form.typeYourAnswerHere')}
                {...register('endTempo', {
                  required: {
                    value: true,
                    message: t('form.fieldRequired'),
                  },
                  min: { value: 40, message: t('form.bpmMin') },
                  max: { value: 208, message: t('form.bpmMax') },
                })}
              />
              <button
                className='tap-tempo'
                onClick={handleEndTempoTap}
                type='button'
              >
                Tap Tempo
              </button>
            </div>
            {errors.endTempo?.message && (
              <span className='error'>{errors.endTempo.message}</span>
            )}
          </InputGroup>
        )}
      </form>
      {currentStep === 3 && <AddParts parts={parts} setParts={setParts} />}
      <Navigation>
        <button
          className='nav-item'
          onClick={hasPrev() ? prev : cancel}
          type='button'
        >
          <IoChevronBack />
        </button>
        {hasNext() && (
          <button
            className={`nav-item ${!hasNext() || !isValid ? 'disabled' : ''}`}
            type='button'
            onClick={hasNext() ? next : undefined}
            disabled={!hasNext || !isValid}
          >
            <span className='next'>{t('dialog.next')}</span>
            <IoChevronForward />
          </button>
        )}
        {!hasNext() && (
          <button
            className={`nav-item ${
              !isValid || parts.length === 0 ? 'disabled' : ''
            }`}
            type='submit'
            form='add-new-piece'
            disabled={!hasNext || !isValid || parts.length === 0}
          >
            <span className='next'>{t('dialog.save')}</span>
            <IoChevronForward />
          </button>
        )}
      </Navigation>
    </PartsPieceWrapper>
  );
}
