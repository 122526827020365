import styled from 'styled-components';
import {
  auth,
  facebookAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
  analytics,
} from '../lib/firebase';
import { logEvent } from 'firebase/analytics';
import toast from 'react-hot-toast';
import { signInWithPopup } from '@firebase/auth';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as GoogleIcon } from '../assets/icons/google.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/twitter.svg';
import { useTranslation } from 'react-i18next';

const StyledSignInWith = styled.section`
  margin-top: 3rem;

  & .divider {
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.colors.red};
    overflow: hidden;
    font-weight: 400;
    text-align: center;
    height: 1.4rem;

    &:before,
    &:after {
      background-color: ${(props) => props.theme.colors.red};
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &:before {
      right: 1.2rem;
      margin-left: -50%;
    }

    &:after {
      left: 1.2rem;
      margin-right: -50%;
    }
  }

  & .icon-box {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;

    & svg {
      margin: 0 2.5rem;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
      transition: filter 0.15s ease-in-out;

      &:hover {
        cursor: pointer;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25)) brightness(90%);
      }

      &:active {
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25)) brightness(90%);
      }
    }
  }
`;

function SignInWith() {
  const { t } = useTranslation();

  const handleGoogle = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
      logEvent(analytics, 'sign_up', { method: 'Google' });
      toast.success(t('toast.loginSuccess'));
    } catch (error: any) {
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          toast.error(t('toast.signInPopupClosed'));
          break;
        default:
          toast.error(t('toast.error'));
      }
    }
  };

  const handleFacebook = async () => {
    try {
      await signInWithPopup(auth, facebookAuthProvider);
      logEvent(analytics, 'sign_up', { method: 'Facebook' });
      toast.success(t('toast.loginSuccess'));
    } catch (error: any) {
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          toast.error(t('toast.signInPopupClosed'));
          break;
        default:
          toast.error(t('toast.error'));
      }
    }
  };

  const handleTwitter = async () => {
    try {
      await signInWithPopup(auth, twitterAuthProvider);
      logEvent(analytics, 'sign_up', { method: 'Twitter' });
      toast.success(t('toast.loginSuccess'));
    } catch (error: any) {
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          toast.error(t('toast.signInPopupClosed'));
          break;
        default:
          toast.error(t('toast.error'));
      }
    }
  };

  return (
    <StyledSignInWith>
      <h6 className='divider'>{t('signin.orSignInVia')}</h6>
      <div className='icon-box'>
        <FacebookIcon onClick={handleFacebook} />
        <GoogleIcon onClick={handleGoogle} />
        <TwitterIcon onClick={handleTwitter} />
      </div>
    </StyledSignInWith>
  );
}

export default SignInWith;
