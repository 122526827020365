import { ReactElement, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { sendSignInLinkToEmail } from '@firebase/auth';
import { useForm, SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import { IoCheckmark } from 'react-icons/io5';
import { device } from '../../shared/breakpoints';
import Wrapper from '../Wrapper';
import Button from '../Button';
import SignInWith from '../SignInWith';
import Jumbotron from '../Jumbotron';
import StyledForm from '../styled/StyledForm';
import { auth, actionCodeSettings } from '../../lib/firebase';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import { Helmet } from 'react-helmet-async';
import defaultTheme from '../../theme';
import { useTranslation, Trans } from 'react-i18next';

type Inputs = {
  email: string;
  password: string;
};

type HeadingProps = {
  center?: boolean;
};

const fadeIn = keyframes`
  0%   { opacity: 0.1; transform: scale(0.5);}
	100% { opacity: 1; transform: scale(1); }
`;

const Heading = styled.div<HeadingProps>`
  margin-bottom: 1.5rem;

  & h3 {
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.big};
    margin-bottom: 1rem;
  }

  & .checkmark {
    text-align: center;

    & svg {
      animation: 1s ${fadeIn};
      margin-top: 2.5rem;
      color: ${(props) => props.theme.colors.red};
      font-size: 7rem;
    }
  }
`;

const TextBlock = styled.p`
  text-align: center;
  line-height: 2.4rem;
  font-size: ${(props) => props.theme.fontSize.normal};
  font-weight: 300;
  margin-bottom: 1.4rem;
`;

const Content = styled.section`
  color: ${(props) => props.theme.colors.blue};
  padding: 5rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  height: 37.4rem;

  @media ${device.laptop} {
  }
`;

const Consent = styled.div`
  font-size: 1rem;
  text-align: center;
  margin-top: 0.8rem;

  & a {
    color: ${(props) => props.theme.colors.red};

    &:hover {
      color: ${(props) => darken(0.2, props.theme.colors.red)};
    }

    &:visited {
      color: ${(props) => props.theme.colors.red};
    }
  }
`;

const EmailSentCard = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 2rem 1rem;
  box-shadow: 0px 0px 30px 70px rgba(255, 255, 255, 0.9);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function SignIn(): ReactElement {
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onBlur',
    shouldUseNativeValidation: false,
  });

  const login: SubmitHandler<Inputs> = async (data) => {
    try {
      await toast.promise(
        sendSignInLinkToEmail(auth, data.email, actionCodeSettings),
        {
          loading: t('toast.loading'),
          success: t('toast.emailSent'),
          error: t('toast.error'),
        }
      );

      setEmail(data.email);
      window.localStorage.setItem('emailForSignIn', data.email);
      setEmailSent(true);
    } catch (error: any) {
      t('toast.error');
    }
  };

  const emailSentContent = (
    <EmailSentCard>
      <Heading center>
        <h3>{t('signin.checkEmail')}</h3>
        <div className='checkmark'>
          <IoCheckmark />
        </div>
      </Heading>
      <TextBlock>{t('signin.weEmailedALink', { mail: email })}</TextBlock>
      <TextBlock>{t('signin.clickLink')}</TextBlock>
    </EmailSentCard>
  );

  const defaultContent = (
    <>
      <Heading>
        <h3>{t('signin.signIn')}</h3>
      </Heading>
      <StyledForm onSubmit={handleSubmit(login)} dark>
        <input
          type='email'
          placeholder={t('signin.email')}
          {...register('email', {
            required: { value: true, message: t('form.fieldRequired') },
            minLength: {
              value: 5,
              message: t('form.emailMinLength'),
            },
          })}
        />
        {errors.email?.message && (
          <span className='error'>{errors.email.message}</span>
        )}
        <Button
          label={t('signin.signInButton')}
          type='button'
          block
          marginTop
          variant='red'
        />
        <Consent>
          <Trans i18nKey='signin.terms'>
            By signing up, you agree to the{' '}
            <Link to='/terms'>Terms of Service</Link>
            and
            <Link to='/privacy'>Privacy Policy</Link>.
          </Trans>
        </Consent>
      </StyledForm>
      <SignInWith />
    </>
  );

  return (
    <Wrapper isSignUpOrIn hasImage bg='white'>
      <Helmet>
        <meta name='theme-color' content={defaultTheme.colors.white} />
      </Helmet>
      <Jumbotron
        heading='Practory'
        subheading="don't think about what to practice ever again"
      />
      <Content>{emailSent ? emailSentContent : defaultContent}</Content>
    </Wrapper>
  );
}

export default SignIn;
