import { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
} from '@firebase/auth';
import { useForm, SubmitHandler } from 'react-hook-form';
import { darken } from 'polished';
import { auth, analytics } from '../../lib/firebase';
import { logEvent } from 'firebase/analytics';
import Wrapper from '../Wrapper';
import Button from '../Button';
import Jumbotron from '../Jumbotron';
import ContentCard from '../ContentCard';
import StyledFrom from '../styled/StyledForm';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';

type Inputs = {
  email: string;
  password: string;
};

type HeadingProps = {
  center?: boolean;
};

const Heading = styled.div<HeadingProps>`
  margin-bottom: 3rem;
  color: ${(props) => props.theme.colors.white};

  & h3 {
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.big};
    margin-bottom: 1rem;
  }

  & h4 {
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSize.normal};

    & a {
      color: ${(props) => props.theme.colors.red};
      text-decoration: none;

      &:hover {
        color: ${(props) => darken(0.08, props.theme.colors.red)};
      }
    }
  }
`;

function Confirm(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(
    window.localStorage.getItem('emailForSignIn')
  );

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      if (email) {
        setLoading(true);
        signInWithEmailLink(auth, email as string, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            onAuthStateChanged(auth, (user) => {
              logEvent(analytics, 'sign_up', { method: 'Email' });
              toast.success(t('toast.loginSuccess'));
              navigate('/practice');
            });
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch((error) => {
            toast.error(t('toast.error'));
            navigate('/signin');
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    } else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    shouldUseNativeValidation: true,
  });

  const login: SubmitHandler<Inputs> = async (data) => {
    setEmail(data.email);
  };

  return (
    <Wrapper isSignUpOrIn>
      <Jumbotron
        heading='Practory'
        subheading="don't think about what to practice ever again"
      />
      <ContentCard variant='signUp' isSignUpOrIn>
        {loading ? (
          <Loader show />
        ) : (
          <>
            <Heading>
              <h3>{t('signin.confirmEmail')}</h3>
              <h4>{t('signin.pleaseProvideEmail')}</h4>
            </Heading>
            <StyledFrom onSubmit={handleSubmit(login)}>
              <label htmlFor='email'>{t('signin.email')}</label>
              <input
                type='email'
                placeholder='mail@mail.com'
                {...register('email', {
                  required: { value: true, message: t('form.fieldRequired') },
                  minLength: {
                    value: 5,
                    message: t('form.emailMinLength'),
                  },
                })}
              />
              {errors.email?.message && (
                <span className='error'>{errors.email.message}</span>
              )}
              <Button
                label={t('signin.confirm')}
                type='button'
                block
                marginTop
              />
            </StyledFrom>
          </>
        )}
      </ContentCard>
    </Wrapper>
  );
}

export default Confirm;
