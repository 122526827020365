import React, { ReactElement, useState } from 'react';
import { Part } from '../contexts/part.context';
import { handleEvent } from '../lib/tapTempo';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputGroup from './styled/InputGroup';
import Button from './Button';
import { useTranslation } from 'react-i18next';

interface Props {
  part: Part;
  cancel: () => void;
  saveChanges: (partToEdit: Part) => void;
}

type Inputs = {
  name: string;
  currentTempo: string;
};

export default function EditPart({
  part,
  cancel,
  saveChanges,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [currentTempoBpm, setCurrentTempoBpm] = useState<number | undefined>(
    undefined
  );

  const handleCurrentTempoTap = () => {
    setCurrentTempoBpm(handleEvent());
    if (currentTempoBpm)
      setValue('currentTempo', `${currentTempoBpm}`, { shouldValidate: true });
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const editedPart = {
      ...part,
      name: data.name,
      currentTempo: parseInt(data.currentTempo),
    };
    saveChanges(editedPart);
    reset({ name: '', currentTempo: '' });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputGroup>
        <label htmlFor='piece' className='label'>
          {t('piece.partName')}
        </label>
        <input
          className='input'
          type='text'
          defaultValue={part.name}
          {...register('name', {
            required: { value: true, message: t('form.fieldRequired') },
            maxLength: {
              value: 25,
              message: t('form.pieceMaxLength'),
            },
          })}
        />
        {errors.name?.message && (
          <span className='error'>{errors.name.message}</span>
        )}
      </InputGroup>

      <InputGroup>
        <label htmlFor='currentTempo' className='label'>
          {t('piece.currentTempo')}
        </label>
        <div className='input-with-button'>
          <input
            type='number'
            className='input input--button'
            defaultValue={part.currentTempo}
            {...register('currentTempo', {
              required: {
                value: true,
                message: t('form.fieldRequired'),
              },
              min: { value: 40, message: t('form.bpmMin') },
              max: { value: 208, message: t('form.bpmMax') },
            })}
          />
          <button
            className='tap-tempo'
            onClick={handleCurrentTempoTap}
            type='button'
          >
            Tap Tempo
          </button>
        </div>
        {errors.currentTempo?.message && (
          <span className='error'>{errors.currentTempo.message}</span>
        )}
      </InputGroup>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '3rem',
        }}
      >
        <Button label={t('piece.saveChanges')} />
        <Button label={t('dialog.cancel')} onClick={cancel} variant='neutral' />
      </div>
    </form>
  );
}
