import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { IoCheckmark, IoHeartOutline } from 'react-icons/io5';
import { logEvent } from 'firebase/analytics';
import { analytics, auth } from '../lib/firebase';
import StyledCard from './styled/StyledCard';
import { createCheckoutSession } from '../lib/stripe/createCheckoutSession';
import Spinner from './styled/Spinner';
import CtaButton from './styled/CtaButton';
import { useTranslation } from 'react-i18next';

const Border = styled.div`
  border: 1px solid ${(props) => props.theme.colors.red};
  border-radius: 8px;
  margin-top: 2rem;
`;

const Cta = styled.div`
  margin-top: 2rem;
`;

const SavingsParagraph = styled.p`
  font-size: ${(props) => props.theme.fontSize.small};
  text-align: center;
  margin-top: 1rem;
`;

export default function UpgradePro(): ReactElement {
  const { t, i18n } = useTranslation();
  const [yearlyLoading, setYearlyLoading] = useState(false);
  const [monthlyLoading, setMonthlyLoading] = useState(false);

  const upgrade = async (model: string) => {
    let price = '';
    if (model === 'monthly') {
      if (i18n.language.startsWith('de')) {
        logEvent(analytics, 'begin_checkout', {
          currency: 'EUR',
          value: 2.99,
        });
        price = process.env.REACT_APP_STRIPE_PRICE_PER_MONTH_EUR as string;
      } else {
        logEvent(analytics, 'begin_checkout', {
          currency: 'USD',
          value: 2.99,
        });
        price = process.env.REACT_APP_STRIPE_PRICE_PER_MONTH_USD as string;
      }
      setMonthlyLoading(true);
    } else {
      if (i18n.language.startsWith('de')) {
        logEvent(analytics, 'begin_checkout', {
          currency: 'EUR',
          value: 19.99,
        });
        price = process.env.REACT_APP_STRIPE_PRICE_PER_YEAR_EUR as string;
      } else {
        logEvent(analytics, 'begin_checkout', {
          currency: 'USD',
          value: 19.99,
        });
        price = process.env.REACT_APP_STRIPE_PRICE_PER_YEAR_USD as string;
      }
      setYearlyLoading(true);
    }

    createCheckoutSession(auth.currentUser?.uid, price);
  };

  return (
    <Border>
      <StyledCard fontColor='white' background='neutral' accent paddingTop>
        <div className='current-plan'>
          <h4>Practory Pro</h4>
        </div>
        <div className='benefits'>
          <ul>
            <li>
              <IoHeartOutline />
              <span>
                <strong>{t('plan.support')}</strong>
              </span>
            </li>
            <li>
              <IoCheckmark />
              <span>
                <strong>{t('plan.unlimitedPieces')}</strong>
              </span>
            </li>
            <li>
              <IoCheckmark />
              <span>
                <strong>{t('plan.unlimitedParts')}</strong>
              </span>
            </li>
          </ul>
        </div>
        <Cta>
          <CtaButton onClick={() => upgrade('yearly')} disabled={yearlyLoading}>
            {yearlyLoading ? (
              <Spinner />
            ) : (
              `19.99${t('plan.currency')} / ${t('plan.year')}`
            )}
          </CtaButton>
          <CtaButton
            outline
            onClick={() => upgrade('monthly')}
            disabled={monthlyLoading}
          >
            {monthlyLoading ? (
              <Spinner />
            ) : (
              `2.99${t('plan.currency')} / ${t('plan.month')}`
            )}
          </CtaButton>
          <SavingsParagraph>{t('plan.savePercent')}</SavingsParagraph>
        </Cta>
      </StyledCard>
    </Border>
  );
}
