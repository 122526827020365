import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { analytics } from '../lib/firebase';
import { logEvent, setCurrentScreen } from 'firebase/analytics';

function FirebaseAnalytics() {
  let location = useLocation();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    setCurrentScreen(analytics, page_path);
    logEvent(analytics, 'page_view', { page_path });
  }, [location]);
  return null;
}

export default FirebaseAnalytics;
