import { ExtendedPart } from '../contexts/part.context';
import practicePriority from './practicePriority';

function partsByPriority(parts: Array<ExtendedPart>) {
  parts.forEach((part) => {
    const { endTempo, currentTempo, importance, lastPracticed } = part;
    part.priority = practicePriority(
      endTempo,
      currentTempo,
      importance,
      lastPracticed
    );
  });
  const orderedParts = [...parts].sort((a, b) => b.priority - a.priority);

  return orderedParts;
}

export default partsByPriority;
