import { ReactElement, useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { updateDoc, doc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import { auth, db } from '../../lib/firebase';
import { PieceContext } from '../../contexts/piece.context';
import practicePriority from '../../lib/practicePriority';
import { handleEvent } from '../../lib/tapTempo';
import Header from '../Header';
import Button from '../Button';
import PartCard from '../PartCard';
import DialogModal from '../DialogModal';
import StyledTextButton from '../styled/StyledTextButton';
import InputGroup from '../styled/InputGroup';
import { Part } from '../../contexts/part.context';
import Wrapper from '../Wrapper';
import EditPart from '../EditPart';
import AddPart from '../AddPart';
import * as Constants from '../../shared/constants';
import { AuthContext } from '../../contexts/auth.context';
import UpgradeDialog from '../UpgradeDialog';
import useProStatus from '../../lib/stripe/useProStatus';
import { useTranslation } from 'react-i18next';

type Inputs = {
  name: string;
  part: string;
  currentTempo?: string;
  endTempo: string;
  importance: string;
  _id: string;
};

const EditPieceWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2.5rem;
  flex: 1;
  flex-direction: column;
  margin-top: 6rem;
`;

export default function EditPiece(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const pieces = useContext(PieceContext);
  const { user } = useContext(AuthContext);
  const isPro = useProStatus(user);
  const pieceToEdit = pieces?.find((piece) => piece._id === id);
  const [showModal, setShowModal] = useState(false);
  const [partToEdit, setPartToEdit] = useState<Part | undefined>(undefined);
  const [isPartToAdd, setIsPartToAdd] = useState(false);
  const [parts, setParts] = useState(pieceToEdit?.parts);
  const [endTempoBpm, setEndTempoBpm] = useState<number | undefined>(undefined);
  const [currentTempoBpm, setCurrentBpm] = useState<number | undefined>(
    undefined
  );
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

  const isWhole = parts?.length === 1;

  // set default values for inputs on mount
  useEffect(() => {
    if (pieceToEdit) {
      setValue('name', `${pieceToEdit?.name}`, { shouldValidate: true });
      setValue('endTempo', `${pieceToEdit?.endTempo}`, {
        shouldValidate: true,
      });
      setValue('importance', `${pieceToEdit?.importance.toString()}`, {
        shouldValidate: true,
      });
      setParts(pieceToEdit.parts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieceToEdit]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    shouldUseNativeValidation: true,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const tooManyParts = parts && parts.length > Constants.MAX_PARTS_ALLOWED;

    if (!isPro && tooManyParts) {
      setShowUpgradeDialog(true);
      return;
    }

    if (pieceToEdit) {
      try {
        // parse form data to number
        const endTempo = parseInt(data.endTempo);
        const importance = parseInt(data.importance);

        // if there's only one part, write current tempo to it
        // and calculate priority with new data
        if (isWhole && data.currentTempo) {
          setParts([
            {
              id: parts[0].id,
              currentTempo: parseInt(data.currentTempo),
              priority: practicePriority(
                endTempo,
                parseInt(data.currentTempo),
                importance,
                parts[0].lastPracticed
              ),
              lastPracticed: parts[0].lastPracticed,
            },
          ]);
        } else {
          // recalculate priority for all parts
          parts?.forEach((part) => {
            part.priority = practicePriority(
              endTempo,
              part.currentTempo,
              importance,
              part.lastPracticed
            );
          });
        }

        // put editedPiece together
        const editedPiece = {
          name: data.name,
          endTempo,
          importance,
          parts,
        };

        if (auth.currentUser) {
          toast.promise(
            updateDoc(
              doc(
                db,
                `/users/${auth.currentUser?.uid}/pieces`,
                pieceToEdit._id
              ),
              { ...editedPiece, updatedAt: serverTimestamp() }
            ),
            {
              loading: t('toast.loading'),
              success: t('toast.saveSuccess', { name: editedPiece.name }),
              error: t('toast.error'),
            }
          );
          navigate('/pieces');
        }
      } catch (error: any) {
        switch (error.code) {
          default:
            t('toast.error');
        }
      }
    }
  };

  const handleEndTempoTap = () => {
    setEndTempoBpm(handleEvent());
    if (endTempoBpm)
      setValue('endTempo', `${endTempoBpm}`, { shouldValidate: true });
  };

  const handleCurrentTempoTap = () => {
    setCurrentBpm(handleEvent());
    if (currentTempoBpm)
      setValue('currentTempo', `${currentTempoBpm}`, { shouldValidate: true });
  };

  const deletePiece = async () => {
    try {
      if (auth.currentUser && auth.currentUser.uid === pieceToEdit?.userId) {
        toast.promise(
          deleteDoc(
            doc(db, `/users/${auth.currentUser?.uid}/pieces`, pieceToEdit._id)
          ),
          {
            loading: t('toast.loading'),
            success: t('toast.deleteSuccess', { name: pieceToEdit.name }),
            error: t('toast.error'),
          }
        );
        navigate('/pieces');
      }
    } catch (error: any) {
      switch (error.code) {
        default:
          t('toast.error');
      }
    }
  };

  const handleEditPartClick = (part: Part) => {
    setPartToEdit(part);
  };

  const savePart = (partToSave: Part) => {
    if (parts) {
      parts.push(partToSave);
    }
    setIsPartToAdd(false);
  };

  const deletePart = (id: string) => {
    const remainingParts = parts?.filter((part) => id !== part.id);
    setParts(remainingParts);
  };

  const editPart = (partToEdit: Part) => {
    if (parts) {
      const updatedParts: Array<Part> = parts.map((part) => {
        return partToEdit.id === part.id ? partToEdit : part;
      });

      setParts(updatedParts);
    }
    setPartToEdit(undefined);
  };

  const partList = parts?.map((part: Part) => (
    // if more than one part exist, create section cards
    <PartCard
      part={part as Part}
      key={part.id}
      deletePart={deletePart}
      editPart={handleEditPartClick}
    />
  ));

  if (partToEdit) {
    return (
      <Wrapper bg='dark' marginBottom>
        <Header
          heading={`${t('piece.edit')} "${
            pieceToEdit ? pieceToEdit.name : 'Piece'
          }"`}
        />
        <EditPieceWrapper>
          <EditPart
            part={partToEdit}
            cancel={() => setPartToEdit(undefined)}
            saveChanges={editPart}
          />
          ;
        </EditPieceWrapper>
      </Wrapper>
    );
  }

  if (isPartToAdd) {
    return (
      <Wrapper bg='dark' marginBottom>
        <Header
          heading={`${t('piece.edit')} "${
            pieceToEdit ? pieceToEdit.name : 'Piece'
          }"`}
        />
        <EditPieceWrapper>
          <AddPart cancel={() => setIsPartToAdd(false)} savePart={savePart} />
        </EditPieceWrapper>
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper bg='dark' marginBottom>
        <Header
          heading={`${t('piece.edit')} "${
            pieceToEdit ? pieceToEdit.name : 'Piece'
          }"`}
        />
        <EditPieceWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <label htmlFor='piece' className='label'>
                {t('piece.name')}
              </label>
              <input
                className='input'
                type='text'
                defaultValue={pieceToEdit && pieceToEdit.name}
                {...register('name', {
                  required: { value: true, message: t('form.fieldRequired') },
                  maxLength: {
                    value: 25,
                    message: t('form.pieceMaxLength'),
                  },
                  minLength: {
                    value: 3,
                    message: t('form.pieceMinLength'),
                  },
                })}
              />
              {errors.name?.message && (
                <span className='error'>{errors.name.message}</span>
              )}
            </InputGroup>

            {isWhole && (
              <InputGroup>
                <label htmlFor='currentTempo' className='label'>
                  {t('piece.currentTempo')}
                </label>
                <div className='input-with-button'>
                  <input
                    type='number'
                    className='input input--button'
                    defaultValue={pieceToEdit && parts && parts[0].currentTempo}
                    {...register('currentTempo', {
                      required: {
                        value: true,
                        message: t('form.fieldRequired'),
                      },
                      min: { value: 40, message: t('form.bpmMin') },
                      max: { value: 208, message: t('form.bpmMax') },
                    })}
                  />
                  <button
                    className='tap-tempo'
                    onClick={handleCurrentTempoTap}
                    type='button'
                  >
                    Tap Tempo
                  </button>
                </div>
                {errors.currentTempo?.message && (
                  <span className='error'>{errors.currentTempo.message}</span>
                )}
              </InputGroup>
            )}

            <InputGroup>
              <label htmlFor='endTempo' className='label'>
                {t('piece.endTempo')}
              </label>
              <div className='input-with-button'>
                <input
                  type='number'
                  className='input input--button'
                  defaultValue={pieceToEdit && pieceToEdit?.endTempo}
                  {...register('endTempo', {
                    required: {
                      value: true,
                      message: t('form.fieldRequired'),
                    },
                    min: { value: 40, message: t('form.bpmMin') },
                    max: { value: 208, message: t('form.bpmMax') },
                  })}
                />
                <button
                  className='tap-tempo'
                  onClick={handleEndTempoTap}
                  type='button'
                >
                  Tap Tempo
                </button>
              </div>
              {errors.endTempo?.message && (
                <span className='error'>{errors.endTempo.message}</span>
              )}
            </InputGroup>

            <InputGroup>
              <p className='label'>{t('piece.urgency')}</p>
              <div className='options'>
                <input
                  className='importance'
                  {...register('importance')}
                  type='radio'
                  name='importance'
                  value='1'
                  id='importance-1'
                />
                <label className='for-importance' htmlFor='importance-1'>
                  1
                </label>
                <input
                  className='importance'
                  {...register('importance')}
                  type='radio'
                  name='importance'
                  value='2'
                  id='importance-2'
                />
                <label className='for-importance' htmlFor='importance-2'>
                  2
                </label>
                <input
                  className='importance'
                  {...register('importance')}
                  type='radio'
                  name='importance'
                  value='3'
                  id='importance-3'
                />
                <label className='for-importance' htmlFor='importance-3'>
                  3
                </label>
                <input
                  className='importance'
                  {...register('importance')}
                  type='radio'
                  name='importance'
                  value='4'
                  id='importance-4'
                />
                <label className='for-importance' htmlFor='importance-4'>
                  4
                </label>
                <input
                  className='importance'
                  {...register('importance')}
                  type='radio'
                  name='importance'
                  value='5'
                  id='importance-5'
                />
                <label className='for-importance' htmlFor='importance-5'>
                  5
                </label>
              </div>
              {isWhole && (
                <div className='legend'>
                  <div>{t('piece.notUrgent')}</div>
                  <div>{t('piece.veryUrgent')}</div>
                </div>
              )}
            </InputGroup>

            {!isWhole && (
              <InputGroup>
                <label htmlFor='endTempo' className='label'>
                  {t('piece.sections')}
                </label>
                {partList}
                <StyledTextButton
                  style={{ width: '100%' }}
                  onClick={() => setIsPartToAdd(true)}
                >
                  + {t('piece.addSection')}
                </StyledTextButton>
              </InputGroup>
            )}

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '4rem',
              }}
            >
              <Button label={t('dialog.save')} type='submit' />
              <Link to='/pieces'>
                <Button
                  label={t('dialog.cancel')}
                  type='reset'
                  variant='neutral'
                />
              </Link>
            </div>
          </form>
          <StyledTextButton
            onClick={() => setShowModal(true)}
            marginTop
            marginBottom
            delete
          >
            {t('dialog.delete')}
          </StyledTextButton>
          <DialogModal
            isOpen={showModal}
            setOpen={setShowModal}
            action={deletePiece}
            text={t('piece.deletePiece', { name: pieceToEdit?.name })}
            actionLabel={t('dialog.delete')}
          />
        </EditPieceWrapper>
      </Wrapper>
      {showUpgradeDialog && (
        <UpgradeDialog
          close={() => setShowUpgradeDialog(false)}
          variant='parts'
        />
      )}
    </>
  );
}
