import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ContentCard from '../ContentCard';
import Header from '../Header';
import { IoAdd } from 'react-icons/io5';
import PieceCard from '../PieceCard';
import Loader from '../Loader';
import { PieceContext } from '../../contexts/piece.context';
import StyledAddPieceButton from '../styled/StyledAddPieceButton';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoPieces = styled.div`
  font-size: ${(props) => props.theme.fontSize.big};
  color: ${(props) => transparentize(0.6, props.theme.colors.white)};
  font-weight: 600;
  text-align: center;
  margin-top: 10rem;
`;

export default function PiecesList(): ReactElement {
  const { t } = useTranslation();
  const pieces = useContext(PieceContext);

  let pieceCards;

  if (pieces) {
    pieceCards = [...pieces]
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map((piece) => <PieceCard key={piece._id} piece={piece} />);
  }

  return (
    <Wrapper>
      <Header heading={t('piecesList.myPieces')} />
      <ContentCard variant='transparent' noPaddingTop>
        <Link to='/new'>
          <StyledAddPieceButton>
            <IoAdd />
            {t('piecesList.addNewPiece')}
          </StyledAddPieceButton>
        </Link>
        <Loader show={!pieces} />
        {pieces?.length === 0 && (
          <NoPieces>{t('practice.noPiecesToPractice')}</NoPieces>
        )}
        {pieces && pieceCards}
      </ContentCard>
    </Wrapper>
  );
}
