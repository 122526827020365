import styled from 'styled-components';
import { darken } from 'polished';

const InputGroup = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  & .label {
    display: inline-block;
    margin-bottom: 1.4rem;
  }

  & .input-with-button {
    display: flex;
  }

  & .input {
    border: 1px solid ${(props) => props.theme.colors.white};
    border-radius: 8px;
    height: 5rem;
    background: transparent;
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSize.normal};
    padding: 12px;
    width: 100%;

    &::placeholder {
      color: ${(props) => props.theme.colors.blue};
    }

    &:focus {
      /* background: ${(props) => props.theme.colors.blue}; */
      border: 1px solid ${(props) => props.theme.colors.blueLight};
      &-visible {
        outline: none;
      }
    }

    &:invalid {
      border: 1px solid ${(props) => props.theme.colors.red};
    }

    &--button {
      display: inline;
      flex: 1;
      border-radius: 8px 0 0 8px;
    }
  }

  & .error {
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.colors.red};
    display: block;
    position: absolute;
    margin-top: 0.7rem;
  }

  & .options {
    display: flex;
    justify-content: space-between;
  }

  & .legend {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: ${(props) => props.theme.fontSize.small};
    margin-top: 1rem;
  }

  [type='checkbox']:checked,
  [type='checkbox']:not(:checked),
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .importance:checked + label,
  .importance:not(:checked) + label {
    position: relative;
    width: 5rem;
    height: 5rem;
    font-size: ${(props) => props.theme.fontSize.normal};
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};
    transition: all 0.15s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.colors.blue};
  }
  .importance:not(:checked) + label {
    background: ${(props) => props.theme.colors.blue};
  }
  .importance:checked + label {
    background: ${(props) => props.theme.colors.blueLight};
  }

  & .tap-tempo {
    height: 5rem;
    padding: 0 1.5rem;
    margin-left: auto;
    margin-right: 0;
    display: inline;
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blue};
    border: none;
    border-radius: 0 8px 8px 0;
    border: 1px solid ${(props) => props.theme.colors.white};
    border-left: 0;
    transition: background 0.15s ease-in-out;

    &:hover {
      background: ${(props) => darken(0.08, props.theme.colors.blue)};
      cursor: pointer;
    }

    &:active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }
`;

export default InputGroup;
