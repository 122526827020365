import React, { ReactElement, useState } from 'react';
import StyledCard from './styled/StyledCard';
import { IoCheckmark, IoHeartOutline } from 'react-icons/io5';
import Button from './Button';
import createCustomerPortalLink from '../lib/stripe/createCustomerPortalLink';
import { useTranslation } from 'react-i18next';

interface CurrentPlanProps {
  isPro: boolean | null;
}

export default function CurrentPlan({ isPro }: CurrentPlanProps): ReactElement {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubscription = () => {
    setLoading(true);
    createCustomerPortalLink();
  };

  if (isPro) {
    return (
      <StyledCard fontColor='white' background='transparent' accent>
        <div className='current-plan'>
          <h4>Practory Pro</h4>
        </div>
        <div className='benefits'>
          <ul>
            <li>
              <IoHeartOutline />
              <span>
                <strong>{t('plan.support')}</strong>
              </span>
            </li>
            <li>
              <IoCheckmark />
              <span>
                <strong>{t('plan.unlimitedPieces')}</strong>
              </span>
            </li>
            <li>
              <IoCheckmark />
              <span>
                <strong>{t('plan.unlimitedParts')}</strong>
              </span>
            </li>
          </ul>
        </div>
        <Button
          variant='blueLight'
          label={t('account.manageSubscription')}
          loading={loading}
          onClick={handleSubscription}
          block
        />
      </StyledCard>
    );
  }

  return (
    <StyledCard fontColor='white' background='transparent'>
      <div className='current-plan'>
        <h4>Practory Free</h4>
      </div>
      <div className='benefits'>
        <ul>
          <li>
            <IoCheckmark />
            <span>{t('plan.upToPieces')}</span>
          </li>
          <li>
            <IoCheckmark />
            <span>{t('plan.upToParts')}</span>
          </li>
        </ul>
      </div>
    </StyledCard>
  );
}
