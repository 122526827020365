/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IoPlay, IoList, IoPerson, IoChatbox } from 'react-icons/io5';
import { device } from '../shared/breakpoints';
import { Helmet } from 'react-helmet-async';
import defaultTheme from '../theme';
import { useTranslation } from 'react-i18next';

interface WithNavigationProps {
  children: React.ReactNode;
}

const StyledNavbar = styled.nav`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: hidden;
  color: ${(props) => props.theme.colors.blueDark};
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.1);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 9999;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media ${device.laptop} {
    padding: 0 25vw 0 25vw;
  }

  @media ${device.laptopL} {
    padding: 0 35vw 0 35vw;
  }

  & .navbar__item {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: ${(props) => props.theme.colors.blueDark};
      background: transparent;
      height: 4.5rem;
      width: 5rem;
      cursor: pointer;

      & svg {
        font-size: 2.1rem;
      }
    }
  }
`;

export default function WithNavigation({
  children,
}: WithNavigationProps): ReactElement {
  const { i18n } = useTranslation();
  const [feedbackId, setFeedbackId] = useState(
    'fa5e87ec-4c21-456a-877a-a3314058d6bd'
  );

  useEffect(() => {
    if (i18n.language.startsWith('de')) {
      setFeedbackId('ef572325-8be1-4f27-8f41-0d9681c8e6c8');
    } else if (i18n.language.startsWith('en')) {
      setFeedbackId('fa5e87ec-4c21-456a-877a-a3314058d6bd');
    }
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <meta name='theme-color' content={defaultTheme.colors.blueDark} />
      </Helmet>
      {children}
      <StyledNavbar>
        <div className='navbar__item'>
          <Link to='/account'>
            <button type='button'>
              <IoPerson size='1.2em' />
            </button>
          </Link>
        </div>
        <div className='navbar__item'>
          <Link to='/practice'>
            <button type='button'>
              <IoPlay size='1.5em' />
            </button>
          </Link>
        </div>
        <div className='navbar__item'>
          <Link to='/pieces'>
            <button type='button'>
              <IoList size='1.3em' />
            </button>
          </Link>
        </div>
        <div className='navbar__item'>
          <a className='feedback' data-az-l={feedbackId}>
            <button type='button'>
              <IoChatbox size='1.2em' />
            </button>
          </a>
        </div>
      </StyledNavbar>
    </>
  );
}
