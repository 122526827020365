import React, { useState, ReactElement, Dispatch, SetStateAction } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import { handleEvent } from '../lib/tapTempo';
import Button from './Button';
import PartCard from './PartCard';
import { Part } from '../contexts/part.context';
import InputGroup from './styled/InputGroup';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

type Inputs = {
  name: string;
  currentTempo: string;
};

interface Props {
  parts: Array<Part>;
  setParts: Dispatch<SetStateAction<Array<Part>>>;
}

const Form = styled.form`
  margin-bottom: 6rem;

  & .button-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export default function AddParts({ parts, setParts }: Props): ReactElement {
  const { t } = useTranslation();
  const [currentTempoBpm, setCurrentTempoBpm] = useState<number | undefined>(
    undefined
  );
  const [isEditing, setIsEditing] = useState(false);
  const [currentPart, setCurrentPart] = useState<Part | null>(null);

  const handleCurrentTempoTap = () => {
    setCurrentTempoBpm(handleEvent());
    if (currentTempoBpm)
      setValue('currentTempo', `${currentTempoBpm}`, { shouldValidate: true });
  };

  const deletePart = (id: string) => {
    const remainingParts = parts.filter((part) => id !== part.id);
    setParts(remainingParts);
  };

  const editPart = (part: Part) => {
    setIsEditing(true);
    setCurrentPart(part);
    setValue('currentTempo', `${part.currentTempo}`, { shouldValidate: true });
    setValue('name', `${part.name}`, { shouldValidate: true });
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newPart: Part = {
      name: data.name,
      currentTempo: parseInt(data.currentTempo),
      id: nanoid(),
      lastPracticed: new Date(),
    };
    setParts([...parts, newPart]);
    reset({ name: '', currentTempo: '' });
  };

  const onSaveChanges: SubmitHandler<Inputs> = (data) => {
    if (currentPart) {
      const updatedPart = {
        ...currentPart,
        name: data.name,
        currentTempo: parseInt(data.currentTempo),
      };

      const updatedParts: Array<Part> = parts.map((part) => {
        return updatedPart.id === part.id ? updatedPart : part;
      });

      setParts(updatedParts);
      reset({ name: '', currentTempo: '' });
      setIsEditing(false);
    }
  };

  const partList = parts.map((part) => (
    <PartCard
      part={part}
      key={part.id}
      deletePart={deletePart}
      editPart={editPart}
    />
  ));

  return (
    <>
      {isEditing ? (
        <Form onSubmit={handleSubmit(onSaveChanges)}>
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('piece.partName')}
            </label>
            <input
              type='text'
              className='input'
              placeholder={t('form.typeYourAnswerHere')}
              defaultValue={currentPart?.name}
              {...register('name', {
                required: { value: true, message: t('form.fieldRequired') },
                maxLength: {
                  value: 25,
                  message: t('form.partMaxLength'),
                },
                minLength: {
                  value: 1,
                  message: t('form.partMinLength'),
                },
              })}
            />
            {errors.name?.message && (
              <span className='error'>{errors.name.message}</span>
            )}
          </InputGroup>
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('piece.currentTempo')}
            </label>
            <div className='input-with-button'>
              <input
                type='number'
                className='input input--button'
                placeholder={t('form.typeYourAnswerHere')}
                defaultValue={currentPart?.currentTempo}
                {...register('currentTempo', {
                  required: { value: true, message: t('form.fieldRequired') },
                  min: { value: 40, message: t('form.bpmMin') },
                  max: { value: 208, message: t('form.bpmMax') },
                })}
              />
              <button
                className='tap-tempo'
                onClick={handleCurrentTempoTap}
                type='button'
              >
                Tap Tempo
              </button>
            </div>
            {errors.currentTempo?.message && (
              <span className='error'>{errors.currentTempo.message}</span>
            )}
          </InputGroup>
          <div className='button-box'>
            <Button variant='blueLight' label={t('piece.saveChanges')} />
          </div>
        </Form>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('piece.partName')}
            </label>
            <input
              type='text'
              className='input'
              placeholder={t('form.typeYourAnswerHere')}
              {...register('name', {
                required: { value: true, message: t('form.fieldRequired') },
                maxLength: {
                  value: 25,
                  message: t('form.partMaxLength'),
                },
              })}
            />
            {errors.name?.message && (
              <span className='error'>{errors.name.message}</span>
            )}
          </InputGroup>
          <InputGroup>
            <label htmlFor='name' className='label'>
              {t('piece.currentTempo')}
            </label>
            <div className='input-with-button'>
              <input
                type='number'
                className='input input--button'
                placeholder={t('form.typeYourAnswerHere')}
                {...register('currentTempo', {
                  required: { value: true, message: t('form.fieldRequired') },
                  min: { value: 40, message: t('form.bpmMin') },
                  max: { value: 208, message: t('form.bpmMax') },
                })}
              />
              <button
                className='tap-tempo'
                onClick={handleCurrentTempoTap}
                type='button'
              >
                Tap Tempo
              </button>
            </div>
            {errors.currentTempo?.message && (
              <span className='error'>{errors.currentTempo.message}</span>
            )}
          </InputGroup>
          <div className='button-box'>
            <Button variant='blueLight' label={t('piece.addSection')} />
          </div>
        </Form>
      )}
      {partList && partList}
    </>
  );
}
