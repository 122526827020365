import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import { auth } from '../lib/firebase';

interface PrivateRouteProps {
  children: React.ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps): any {
  const [user, loading] = useAuthState(auth);

  if (loading) return <div></div>;

  return user ? children : <Navigate to='/signin' />;
}

export default PrivateRoute;
