import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './providers/auth.provider';
import { ThemeProvider } from 'styled-components';
import practoryTheme from './theme';
import { GlobalStyle } from './shared/global';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import FirebaseAnalytics from './shared/FirebaseAnalytics';

import './lib/i18n';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={practoryTheme}>
      <GlobalStyle />
      <AuthProvider>
        <BrowserRouter>
          <FirebaseAnalytics />
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
