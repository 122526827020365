import React, { ReactElement, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { createCheckoutSession } from '../lib/stripe/createCheckoutSession';
import { auth } from '../lib/firebase';
import CtaButton from './styled/CtaButton';
import Spinner from './styled/Spinner';
import { useTranslation } from 'react-i18next';

interface Props {
  close: () => void;
  variant: 'parts' | 'pieces';
}

const fadeIn = keyframes`
  0%   { opacity: 0.1; top: -30px }
	100% { opacity: 1; top: 0px }
`;

const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .content {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    position: relative;
    flex: 0;
    border-radius: 8px;
    margin: 0 25px 0 25px;
    animation: 0.4s ${fadeIn};

    & .close {
      position: absolute;
      top: 9px;
      right: 15px;
      font-size: ${(props) => props.theme.fontSize.big};
      cursor: pointer;
    }

    & .title {
      font-size: ${(props) => props.theme.fontSize.big};
      margin-bottom: 1rem;
    }

    & .text {
      font-size: ${(props) => props.theme.fontSize.small};
      line-height: 1.9rem;
      width: 85%;
    }
  }
`;

const Cta = styled.div`
  margin-top: 3rem;
`;

const SavingsParagraph = styled.p`
  font-size: ${(props) => props.theme.fontSize.small};
  text-align: center;
  margin-top: 1rem;
`;

export default function UpgradeDialog({ close, variant }: Props): ReactElement {
  const [yearlyLoading, setYearlyLoading] = useState(false);
  const [monthlyLoading, setMonthlyLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const upgrade = async (model: string) => {
    let price = '';
    if (model === 'monthly') {
      if (i18n.language.startsWith('de')) {
        price = process.env.REACT_APP_STRIPE_PRICE_PER_MONTH_EUR as string;
      } else {
        price = process.env.REACT_APP_STRIPE_PRICE_PER_MONTH_USD as string;
      }
      setMonthlyLoading(true);
    } else {
      if (i18n.language.startsWith('de')) {
        price = process.env.REACT_APP_STRIPE_PRICE_PER_YEAR_EUR as string;
      } else {
        price = process.env.REACT_APP_STRIPE_PRICE_PER_YEAR_USD as string;
      }
      setYearlyLoading(true);
    }

    createCheckoutSession(auth.currentUser?.uid, price);
  };

  return (
    <Modal>
      <div className='content'>
        <span className='close' onClick={close}>
          &times;
        </span>
        <div className='title'>{t('plan.ohNo')}</div>
        <div className='text'>
          <p>
            {variant === 'parts'
              ? t('plan.exceededNumberOfParts')
              : t('plan.exceededNumberOfPieces')}
          </p>
        </div>
        <Cta>
          <CtaButton onClick={() => upgrade('yearly')} disabled={yearlyLoading}>
            {yearlyLoading ? (
              <Spinner />
            ) : (
              `19.99${t('plan.currency')} / ${t('plan.year')}`
            )}
          </CtaButton>
          <CtaButton
            outline
            onClick={() => upgrade('monthly')}
            disabled={monthlyLoading}
          >
            {monthlyLoading ? (
              <Spinner />
            ) : (
              `2.99${t('plan.currency')} / ${t('plan.month')}`
            )}
          </CtaButton>
          <SavingsParagraph>{t('plan.savePercent')}</SavingsParagraph>
        </Cta>
      </div>
    </Modal>
  );
}
