/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { analytics } from '../../lib/firebase';
import { logEvent } from 'firebase/analytics';
import styled, { keyframes } from 'styled-components';
import { transparentize } from 'polished';
import { IoChevronBack, IoChevronForward, IoBalloon } from 'react-icons/io5';
import Wrapper from '../Wrapper';
import PracticeCard from '../PracticeCard';
import Loader from '../Loader';
import Button from '../Button';
import { device } from '../../shared/breakpoints';
import { ReactComponent as Logo } from '../../assets/images/logo-negative-white.svg';
import { PartContext } from '../../contexts/part.context';
import DailyRoutine from '../DailyRoutine';
import { useTranslation } from 'react-i18next';

const slideInUp = keyframes`
  0% {
  -webkit-transform: translateY(100%) scale(0.7);
  transform: translateY(100%) scale(0.7);
  visibility: visible;
  opacity: 0;
  }
  100% {
  -webkit-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
  opacity: 1;
  }
`;

const LogoHeader = styled.div`
  height: 9.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 2.5rem;

  svg {
    width: 16rem;
  }
`;

const PracticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 750px;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem 1.5rem 1.5rem;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.blue};

  & .nav-item {
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSize.big};
    font-weight: 600;
    padding: 0;
    background-color: transparent;
    border: none;
    width: min-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
  }

  & .back {
    margin-left: 1rem;
  }

  & .next {
    margin-right: 1rem;
  }

  & .disabled {
    color: ${(props) => transparentize(0.7, props.theme.colors.white)};
    cursor: default;
  }

  & svg {
    font-size: 3rem;
  }
`;

const Finished = styled.div`
  background: ${(props) => props.theme.colors.blue};
  padding: 10vh 2.5rem 3.5rem 2.5rem;
  border-radius: 3rem 3rem 0 0;
  height: 60vh;

  @media ${device.laptop} {
    padding: 6rem 6rem 5.5rem 6rem;
  }

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex: 1;
    font-size: ${(props) => props.theme.fontSize.big};
    font-weight: 600;
    color: ${(props) => transparentize(0.6, props.theme.colors.white)};

    & h4 {
      font-size: ${(props) => props.theme.fontSize.big};
      margin-bottom: 3rem;
    }

    & svg {
      font-size: 9.6rem;
      color: ${(props) => transparentize(0.4, props.theme.colors.red)};
      margin-bottom: 2.5rem;
      animation: 1s ${slideInUp};
    }
  }

  & .feedback {
    margin-top: 3rem;
  }
`;

const NoPieces = styled.div`
  color: ${(props) => transparentize(0.6, props.theme.colors.white)};
  font-size: ${(props) => props.theme.fontSize.big};
  font-weight: 600;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;

  & p {
    margin-bottom: 2rem;
  }
`;

export default function Practice(): ReactElement {
  const [partIndex, setPartIndex] = useState(0);
  const [showTodaysRoutine, setShowTodaysRoutine] = useState(true);
  const { t, i18n } = useTranslation();

  const [feedbackId, setFeedbackId] = useState(
    'fa5e87ec-4c21-456a-877a-a3314058d6bd'
  );

  useEffect(() => {
    if (i18n.language.startsWith('de')) {
      setFeedbackId('ef572325-8be1-4f27-8f41-0d9681c8e6c8');
    } else if (i18n.language.startsWith('en')) {
      setFeedbackId('fa5e87ec-4c21-456a-877a-a3314058d6bd');
    }
  }, [i18n.language]);

  let parts = useContext(PartContext);
  const isFirstPiece: boolean = partIndex === 0;

  useEffect(() => {
    if (partIndex === parts?.length) {
      logEvent(analytics, 'finish_practice');
    }
  }, [partIndex, parts?.length]);

  const handleNext = () => {
    if (parts) {
      partIndex < parts.length && setPartIndex(partIndex + 1);
    }
  };

  const handleBack = () => {
    !isFirstPiece && setPartIndex(partIndex - 1);
  };

  const handleStartPractice = () => {
    logEvent(analytics, 'start_practice');
    setShowTodaysRoutine(false);
  };

  if (showTodaysRoutine && parts?.length !== 0) {
    return <DailyRoutine parts={parts} start={handleStartPractice} />;
  }

  const practiceCards = parts?.map((part) => (
    <PracticeCard part={part} key={part.id} handleNext={handleNext} />
  ));

  return (
    <Wrapper bg='dark' marginBottom>
      <LogoHeader>
        <Logo />
      </LogoHeader>
      {practiceCards && practiceCards?.length !== 0 ? (
        <PracticeWrapper>
          {partIndex === practiceCards?.length ? (
            <Finished>
              <div className='content'>
                <h4>{t('practice.congratulations')}</h4>
                <IoBalloon />
                <p>{t('practice.youDidIt')}</p>
                <a className='feedback' data-az-l={feedbackId}>
                  <Button
                    variant='red'
                    label={t('practice.tellUsWhatYouThink')}
                  />
                </a>
              </div>
            </Finished>
          ) : (
            practiceCards[partIndex]
          )}
          <Navigation>
            <button
              className={`nav-item ${isFirstPiece ? 'disabled' : ''}`}
              onClick={!isFirstPiece ? handleBack : undefined}
              disabled={isFirstPiece}
            >
              <IoChevronBack />
              <span className='back'>{t('dialog.back')}</span>
            </button>
            <button
              className={`nav-item ${
                partIndex === practiceCards?.length ? 'disabled' : ''
              }`}
              type='submit'
              form='metronome-form'
              disabled={partIndex === practiceCards?.length}
            >
              <span className='next'>{t('dialog.next')}</span>
              <IoChevronForward />
            </button>
          </Navigation>
        </PracticeWrapper>
      ) : practiceCards?.length === 0 ? (
        <NoPieces>
          <p>{t('practice.noPiecesToPractice')}</p>
          <Link to='/new'>
            <Button label={t('practice.addFirstPiece')} variant='red' jump />
          </Link>
        </NoPieces>
      ) : (
        <Loader show />
      )}
    </Wrapper>
  );
}
