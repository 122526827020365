import { Timestamp, FieldValue } from '@firebase/firestore';
import React from 'react';
import { Part } from './part.context';

export interface Piece {
  _id: string;
  name: string;
  endTempo: number;
  importance: number;
  createdAt: Timestamp | FieldValue;
  updatedAt: Timestamp | FieldValue;
  userId: string;
  parts: Array<Part>;
}

export interface Pieces extends Array<Piece> {}

export const PieceContext = React.createContext<Pieces | undefined>(undefined);
