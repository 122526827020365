import { collection, addDoc, onSnapshot } from '@firebase/firestore';
import { auth, db } from '../firebase';
import { toast } from 'react-hot-toast';
import initializeStripe from './initializeStripe';

export async function createCheckoutSession(
  uid: string | undefined,
  price: string
) {
  const docRef = await addDoc(
    collection(db, `/users/${auth.currentUser?.uid}/checkout_sessions`),
    {
      price: price,
      success_url: `${window.location.origin}/account`,
      cancel_url: `${window.location.origin}/account`,
      automatic_tax: true,
      allow_promotion_codes: true,
    }
  );

  onSnapshot(docRef, async (snap) => {
    const { error, sessionId }: any = snap.data();
    if (error) {
      // Show an error to your customer and
      // inspect your Cloud Function logs in the Firebase console.
      toast.error('Oops. Something went wrong.');
    }
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      const stripe = await initializeStripe();
      stripe?.redirectToCheckout({ sessionId });
    }
  });
}
