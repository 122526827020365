import React, { ReactElement } from 'react';
import ContentCard from '../ContentCard';
import Wrapper from '../Wrapper';
import Header from '../Header';
import styled from 'styled-components';
import { darken } from 'polished';

const PrivacyStyles = styled.section`
  h4 {
    font-size: 2.4rem;
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    font-weight: 300;
    font-size: ${(props) => props.theme.fontSize.normal};
  }

  h3 {
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    font-size: ${(props) => props.theme.fontSize.big};
  }

  & p {
    margin-bottom: 1rem;
    font-weight: 200;
    line-height: 2rem;
    font-size: ${(props) => props.theme.fontSize.small};
  }

  span {
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  ul {
    margin-top: 1rem;
    list-style-type: circle;
    list-style-position: inside;
  }

  a {
    color: ${(props) => props.theme.colors.red};
    text-decoration: underline;

    &:hover {
      color: ${(props) => darken(0.2, props.theme.colors.red)};
    }

    &:visited {
      color: ${(props) => props.theme.colors.red};
      text-decoration: underline;
    }
  }
`;

export default function Terms(): ReactElement {
  return (
    <Wrapper bg='white'>
      <Header heading='Terms of Service' font='dark' />
      <ContentCard variant='transparent' font='dark' noPaddingTop>
        <PrivacyStyles>
          <p>
            By accessing or using any website or service made available by
            practory.io, you acknowledge that you (i) have read and agree to be
            bound by these terms of service and (ii) agree to comply with all
            applicable laws and regulations, including U.S. federal, state, and
            local laws and regulations and export and re-export control laws and
            regulations.
          </p>
          <p>
            It is your responsibility to review these terms of service
            periodically. If at any time you find these terms of service
            unacceptable or if you do not agree to these terms of service,
            please do not access the services.
          </p>
          <p>
            <span className='font-weight-bold'>
              About Intellectual Property
            </span>
            <br />
            The service and its original features, content and functionality are
            and will remain the exclusive property of practory.io. The service
            is protected by laws of both the United States and foreign
            countries.
          </p>
          <p>
            If you have reason to believe that your copyright-protected work was
            posted on practory.io without authorisation, you may submit a
            copyright infringement notification. Make sure that you consider
            whether fair use, fair dealing or a similar exception to copyright
            applies before you contact us. These requests should be sent by the
            copyright owner or an agent authorised to act on the owner&apos;s
            behalf.
          </p>
          <p>
            The fastest and simplest way to submit a copyright takedown notice
            is via e-mail:
            <a href='mailto:practoryio@gmail.com' className='ml-1'>
              practoryio@gmail.com
            </a>
          </p>
          <p>
            <span className='font-weight-bold'>Submitting a piece</span>
            <br />
            You have the possibility to submit pieces at practory.io.
          </p>
          <p>
            By clicking the necessary buttons to submit a product or alternative
            (for example „Add piece“ or „Send“) you give us your consent to
            collect, use and process the submitted data.
          </p>
          <p>Please see our Privacy Policy for further information.</p>
          <p>
            <span className='font-weight-bold'>Newsletter</span>
            <br />
            By signing up, you register for our newsletter and will receive
            regular updates. If you wish to unsubscribe to the newsletter you
            can click on the unsubscribe link in the newsletter or contact us
            directly via email.
          </p>
          <p>
            <span className='font-weight-bold'>Links to other webpages</span>
            <br />
            Our service may contain links to third party webpages, shops or
            services that are not owned or controlled by practory.io
          </p>
          <p>
            practory.io has no control over, and assumes no responsibility for
            the content, privacy policies, or practices of any third party
            webpages, shops or services. We do not warrant the offerings of any
            of these entities/individuals or their webpages.
          </p>
          <p>
            You acknowledge and agree that practory.io shall not be responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such content, goods or services available on or through any such
            third party webpages, shops or services.
          </p>
          <p>
            We strongly advise you to read the terms and conditions and privacy
            policies of any third party webpages, shops or services that you
            visit.
          </p>
          <p>
            <span className='font-weight-bold'>Limitation of Liability</span>
            <br />
            In no event shall practory.io, nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from (i) your access to or use
            of or inability to access or use the service; (ii) any conduct or
            content of any third party on the service; (iii) any content
            obtained from the service; and (iv) unauthorized access, use or
            alteration of your transmissions or content, whether based on
            warranty, contract, tort (including negligence) or any other legal
            theory, whether or not we have been informed of the possibility of
            such damage, and even if a remedy set forth herein is found to have
            failed of its essential purpose.
          </p>
          <p>
            <span className='font-weight-bold'>Indemnification</span>
            <br />
            You agree to defend, hold harmless and indemnify practory.io and its
            licensors, and their employees, contractors, agents, officers and
            directors, from and against any and all damages, obligations,
            claims, losses, liabilities, debt or costs and expenses (including,
            but not limited to attorney&apos;s fees), resulting from or arising
            out of a) a breach of these terms, or b) your use and access of this
            service.
          </p>
          <p>
            <span className='font-weight-bold'>Disclaimer</span>
            <br />
            Your use of our service is at your own risk. The service is provided
            on an &quot;as is&quot; and &quot;as available&quot; basis and
            without warranties of any kind, whether express or implied,
            including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, non-infringement
            or course of performance.
          </p>
          <p>
            practory.io and its affiliates, and its licensors do not warrant
            that a) the service will function uninterrupted, secure or available
            at any particular time or location; b) any errors or defects will be
            corrected; c) the service is free of viruses or other harmful
            components; or d) the results of using the service will meet your
            goals and requirements.
          </p>
          <p>
            <span className='font-weight-bold'>Exclusions</span>
            <br />
            Some jurisdictions do not allow the exclusion or limitation of
            liability for consequential or incidental damages, or the exclusion
            of certain warranties, so the limitations above may not apply to
            you.
          </p>
          <p>
            <span className='font-weight-bold'>The Governing Law</span>
            <br />
            These terms shall be governed and construed in accordance with the
            laws of United States of America, without regard to its conflict of
            law provisions. Failure to enforce any right or provision of these
            terms will not be considered a waiver of those rights.
            <br />
            If any of these terms is held to be invalid or unenforceable by a
            court, the remaining provisions of these terms will remain in
            effect. These terms constitute the entire agreement between us
            regarding our service and supersede and replace any prior agreements
            we might have had between us regarding the service.
          </p>
          <p>
            <span className='font-weight-bold'>Changes in terms</span>
            <br />
            We reserve the right, at our sole discretion, to replace or modify
            these terms at any time. If a revision is material we will provide
            at least 30 days notice prior to any new terms taking effect. What
            constitutes a material change will be determined at our sole
            discretion..
          </p>
          <p>
            By continuing to access or use our service after any revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to use
            our service.
          </p>
        </PrivacyStyles>
      </ContentCard>
    </Wrapper>
  );
}
