import styled from 'styled-components';
import { ReactComponent as Logo } from '../assets/images/logo.svg';

const StyledJumbotron = styled.section`
  padding: 0 2.5rem;
  margin-bottom: 29vh;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 30rem;
  flex: 1;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.blueDark};
  font-weight: 400;

  @media (max-width: 320px), (max-height: 670px) {
    margin-bottom: 10vh;
  }

  & h1 {
    font-size: ${(props) => props.theme.fontSize.huge};
    width: 100%;
  }

  & h2 {
    text-align: left;
    font-size: ${(props) => props.theme.fontSize.big};
    width: 25rem;
    margin-top: 1rem;
  }
`;

interface Props {
  heading: string;
  subheading: string;
}

function Jumbotron({ heading, subheading }: Props) {
  return (
    <StyledJumbotron>
      <Logo />
      <h2>{subheading}</h2>
    </StyledJumbotron>
  );
}

export default Jumbotron;
