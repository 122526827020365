import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';
import Spinner from './styled/Spinner';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  variant?: 'red' | 'blue' | 'blueLight' | 'neutral';
  block?: boolean;
  marginTop?: boolean;
  size?: 'md' | 'sm';
  jump?: boolean;
  loading?: boolean;
}

const jump = keyframes`  
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0,0,0,.15);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
  }
`;

const StyledButton = styled.button<ButtonProps>`
  position: relative;
  background: ${(props) => {
    if (props.variant === 'red') return props.theme.colors.red;
    else if (props.variant === 'blue') return props.theme.colors.blue;
    else if (props.variant === 'blueLight') return props.theme.colors.blueLight;
    else if (props.variant === 'neutral')
      return props.theme.colors.neutralLight;
    else return props.theme.colors.blueLight;
  }};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 8px;
  height: 5.2rem;
  padding: 1.4rem 2rem;
  margin-top: ${(props) => (props.marginTop ? '0' : '1.5rem')};
  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.normal};
  width: ${(props) => (props.block ? '100%' : 'auto')};
  transition: background 0.15s ease-in-out;
  min-width: ${(props) => {
    if (props.size === 'sm') return '12rem';
    else return '15rem';
  }};
  animation: ${(props) =>
    props.jump ? css`0.4s ${jump} ease infinite alternate` : ''};
  /* animation: 0.4s ${jump} ease infinite alternate; */

  &:hover {
    background: ${(props) => {
      if (props.variant === 'red') return darken(0.08, props.theme.colors.red);
      else if (props.variant === 'blue')
        return darken(0.08, props.theme.colors.blue);
      else if (props.variant === 'blueLight')
        return darken(0.08, props.theme.colors.blueLight);
      else if (props.variant === 'neutral')
        return darken(0.08, props.theme.colors.neutralLight);
      else return darken(0.08, props.theme.colors.blueLight);
    }};
    cursor: pointer;
  }

  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &:disabled {
    background: ${(props) => {
      if (props.variant === 'blue')
        return darken(0.08, props.theme.colors.blue);
      else if (props.variant === 'blueLight')
        return darken(0.08, props.theme.colors.blueLight);
      else if (props.variant === 'red')
        return darken(0.08, props.theme.colors.red);
      else if (props.variant === 'neutral')
        return darken(0.08, props.theme.colors.neutralLight);
      else return darken(0.08, props.theme.colors.blueLight);
    }};
    cursor: auto;
  }
`;

function Button(
  {
    label = 'Submit',
    variant,
    block,
    onClick,
    size,
    jump,
    loading,
  }: ButtonProps,
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  return (
    <StyledButton
      {...props}
      variant={variant}
      block={block}
      onClick={onClick}
      size={size}
      disabled={loading}
      jump={jump}
    >
      {loading ? <Spinner /> : label}
    </StyledButton>
  );
}

export default Button;
