import styled, { keyframes } from 'styled-components';

const loadingSpinner = keyframes`
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
`;

const Spinner = styled.span`
  content: '';
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: ${loadingSpinner} 1s ease infinite;
`;

export default Spinner;
