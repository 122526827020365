import styled from 'styled-components';
import { rgba } from 'polished';

export default styled.button`
  height: 4.8rem;
  width: 100%;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 8px;
  font-size: ${(props) => props.theme.fontSize.normal};
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  transition: background 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    background: ${(props) => rgba(props.theme.colors.blueLight, 0.15)};
  }

  & svg {
    font-size: 2.1rem;
    margin-right: 1rem;
  }
`;
