import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { darken } from 'polished';
import { auth, db } from '../lib/firebase';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { IoPauseCircle, IoPlayCircle } from 'react-icons/io5';
import { ExtendedPart } from '../contexts/part.context';
import { updateDoc, doc, serverTimestamp } from '@firebase/firestore';
import Metronome from '../lib/metronome';
import { device } from '../shared/breakpoints';
import { PieceContext } from '../contexts/piece.context';
import { useTranslation } from 'react-i18next';

interface Props {
  part: ExtendedPart;
  handleNext: () => void;
}

const Title = styled.div`
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 2.5rem;
  margin-bottom: 2rem;

  & .part {
    padding-left: 1rem;
    min-width: 12.5rem;
    text-align: right;
  }
`;

const Card = styled.section`
  background: ${(props) => props.theme.colors.blue};
  padding: 10vh 2.5rem 3.5rem 2.5rem;
  border-radius: 3rem 3rem 0 0;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    padding: 6rem 6rem 5.5rem 6rem;
  }
`;

const MiddleBlock = styled.div`
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & .heading {
    font-weight: 400;
    margin-bottom: 1rem;
  }

  & .current-tempo {
    font-size: ${(props) => props.theme.fontSize.huge};
    font-weight: 300;
    margin-bottom: 5vh;
  }

  & .range {
    --thumbSize: 2rem;
    --trackSize: 4px;
    --thumbBg: ${(props) => props.theme.colors.red};
    --trackBg: ${(props) => props.theme.colors.white};
    --progressBg: ${(props) => props.theme.colors.red};

    /* webkit progress workaround */
    --webkitProgressPercent: 0%;
  }

  & .range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: var(--thumbSize);
    width: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
  }
  & .range:focus {
    outline: none;
  }

  /* Thumb */
  & .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  & .range::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  & .range::-ms-thumb {
    -ms-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }

  /* Track */
  & .range::-webkit-slider-runnable-track {
    height: var(--trackSize);
    background-image: linear-gradient(
      90deg,
      var(--progressBg) var(--webkitProgressPercent),
      var(--trackBg) var(--webkitProgressPercent)
    );
    border-radius: calc(var(--trackSize) / 2);
  }
  & .range::-moz-range-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
    border-radius: calc(var(--trackSize) / 2);
  }
  & .range::-ms-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
    border-radius: calc(var(--trackSize) / 2);
  }

  /* Progress */
  & .range::-moz-range-progress {
    height: var(--trackSize);
    background-color: var(--progressBg);
    border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
  }
  & .range::-ms-fill-lower {
    height: var(--trackSize);
    background-color: var(--progressBg);
    border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
  }

  & .start-stop {
    color: ${(props) => props.theme.colors.red};
    background-color: transparent;
    height: 8rem;
    width: 8rem;
    border: none;
    padding: 0;
    margin-top: 5vh;
    transition: color 0.15s ease-in-out;

    & svg {
      height: 100%;
      width: 100%;

      &:active {
        color: ${(props) => props.theme.colors.red};
      }
    }

    &:hover {
      cursor: pointer;
      color: ${(props) => darken(0.08, props.theme.colors.red)};
    }
  }

  & .information {
    text-align: center;
    margin-top: 3.5rem;
    font-weight: 400;
    line-height: 2.4rem;
  }
`;

const metronome = new Metronome();

export default function PracticeCard({
  part,
  handleNext,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [bpm, setBpm] = useState(part.currentTempo);
  const [playing, setPlaying] = useState(false);
  const pieces = useContext(PieceContext);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isChanging, setIsChanging] = useState(false);
  const { ref, ...rest } = register('tempo');

  const getPercent = useMemo(
    () => (value: number) => ((value - 40) / (part.endTempo + 20 - 40)) * 100,
    [part.endTempo]
  );

  const changeInputProgressPercentStyle = useCallback(() => {
    inputRef?.current?.style.setProperty(
      '--webkitProgressPercent',
      `${getPercent(bpm)}%`
    );
  }, [getPercent, bpm]);

  useEffect(() => {
    metronome.unmute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metronome]);

  useEffect(() => {
    changeInputProgressPercentStyle();
    const inputElement = inputRef.current;

    const handleUpAndLeave = () => setIsChanging(false);
    const handleDown = () => setIsChanging(true);

    inputElement?.addEventListener(
      'mousemove',
      changeInputProgressPercentStyle
    );
    inputElement?.addEventListener('mousedown', handleDown);
    inputElement?.addEventListener('touchstart', handleDown);
    inputElement?.addEventListener('mouseup', handleUpAndLeave);
    inputElement?.addEventListener('touchend', handleUpAndLeave);
    inputElement?.addEventListener('mouseleave', handleUpAndLeave);
    return () => {
      inputElement?.removeEventListener(
        'mousemove',
        changeInputProgressPercentStyle
      );
      inputElement?.removeEventListener('mousedown', handleDown);
      inputElement?.addEventListener('touchstart', handleDown);
      inputElement?.removeEventListener('mouseup', handleUpAndLeave);
      inputElement?.addEventListener('touchend', handleUpAndLeave);
      inputElement?.removeEventListener('mouseleave', handleUpAndLeave);
    };
  }, [isChanging, changeInputProgressPercentStyle]);

  useEffect(() => {
    if (!inputRef?.current) return;
    changeInputProgressPercentStyle();
  }, [inputRef, changeInputProgressPercentStyle]);

  useEffect(() => {
    if (playing && !isChanging) {
      metronome.tempo = bpm;
      metronome.startStop();
    }
    return () => {
      metronome.stop();
    };
  }, [bpm, playing, isChanging]);

  const toggleStartStop = () => {
    setPlaying(!playing);
  };

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    try {
      // find parent piece to current part
      let piece = pieces?.find((piece) => piece._id === part.pieceId);

      // remove excess elements from part
      (['piece', 'pieceId', 'importance', 'endTempo'] as const).forEach(
        (e) => delete part[e]
      );

      // update part with new values
      const updatedPart = {
        ...part,
        currentTempo: bpm,
        lastPracticed: new Date(),
      };

      // update parts array in piece with updated part
      const updatedParts = piece?.parts.map((part) =>
        updatedPart.id === part.id ? updatedPart : part
      );

      // add updated parts to piece
      const updatedPiece = {
        ...piece,
        parts: updatedParts,
        updatedAt: serverTimestamp(),
      };

      if (auth.currentUser && updatedPiece._id) {
        toast.promise(
          updateDoc(
            doc(db, `/users/${auth.currentUser?.uid}/pieces`, updatedPiece._id),
            updatedPiece
          ),
          {
            loading: t('toast.loading'),
            success: t('toast.saveSuccess', { name: updatedPiece.name }),
            error: t('toast.error'),
          }
        );
        handleNext();
      }
    } catch (error: any) {
      switch (error.code) {
        default:
          console.log(error);
          toast.error(t('toast.error'));
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBpm(e.target.valueAsNumber);
  };

  return (
    <>
      <Title>
        <div className='piece'>{part.piece}</div>
        <div className='part'>{part.name}</div>
      </Title>
      <Card>
        <MiddleBlock>
          <div className='heading'>Tempo</div>
          <div className='current-tempo'>{bpm} bpm</div>
          <form
            id='metronome-form'
            onSubmit={handleSubmit(onSubmit)}
            style={{ width: '100%' }}
          >
            <input
              {...rest}
              ref={inputRef}
              type='range'
              defaultValue={part.currentTempo}
              min='40'
              max={part.endTempo + 20}
              className='range'
              onChange={handleChange}
            />
          </form>
          <button className='start-stop' onClick={toggleStartStop}>
            {playing ? <IoPauseCircle /> : <IoPlayCircle />}
          </button>
          <div className='information'>
            <p>
              {t('piece.endTempo')}: {part?.endTempo} bpm
            </p>
            <p>
              {t('piece.urgency')}: {part?.importance} / 5
            </p>
          </div>
        </MiddleBlock>
      </Card>
    </>
  );
}
