import React from 'react';

export interface Part {
  id: string;
  name?: string;
  currentTempo: number;
  priority?: number;
  lastPracticed: Date;
}

export interface ExtendedPart extends Part {
  piece: string;
  pieceId: string;
  endTempo: number;
  importance: number;
  priority: number;
}

export const PartContext = React.createContext<Array<ExtendedPart> | undefined>(
  undefined
);
