import React, { ReactElement, useContext, useState } from 'react';
import { signOut } from '@firebase/auth';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { auth } from '../../lib/firebase';
import ContentCard from '../ContentCard';
import Wrapper from '../Wrapper';
import Header from '../Header';
import { AuthContext } from '../../contexts/auth.context';
import DialogModal from '../DialogModal';
import { IoPerson } from 'react-icons/io5';
import StyledTextButton from '../styled/StyledTextButton';
import CurrentPlan from '../CurrentPlan';
import UpgradePro from '../UpgradePro';
import useProStatus from '../../lib/stripe/useProStatus';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';

const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.dark};
  margin-bottom: 2rem;

  & .avatar {
    border-radius: 50%;
    overflow: hidden;
    height: 8.5rem;
    width: 8.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    & svg {
      color: ${(props) => props.theme.colors.white};
      height: 5rem;
      width: 5rem;
    }

    & img {
      height: 100%;
      width: 100%;
    }
  }

  & .username {
    color: ${(props) => props.theme.colors.white};
    flex: 1;
    font-size: ${(props) => props.theme.fontSize.normal};
  }
`;

export default function Account(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [showLogoutModal, setLogoutModal] = useState(false);
  const isPro = useProStatus(user);

  const logout = async () => {
    try {
      await signOut(auth);
      toast.error(t('toast.signOutSuccess'));
      navigate('/signin');
    } catch {
      toast.error(t('toast.error'));
    }
  };

  const Content = (
    <>
      <CurrentPlan isPro={isPro} />
      {!isPro && <UpgradePro />}
      <StyledTextButton
        onClick={() => setLogoutModal(true)}
        marginTop
        marginBottom
        delete
      >
        {t('account.logout')}
      </StyledTextButton>
    </>
  );

  return (
    <Wrapper bg='dark'>
      <Header heading='Account' />
      <UserBox>
        <div className='avatar'>
          {user?.photoURL ? (
            <img src={user.photoURL} alt='User' />
          ) : (
            <IoPerson />
          )}
        </div>
        <div className='username'>{user?.displayName || user?.email}</div>
      </UserBox>
      <ContentCard>{isPro !== null ? Content : <Loader show />}</ContentCard>
      <DialogModal
        isOpen={showLogoutModal}
        setOpen={setLogoutModal}
        text={t('account.logoutOfPractory')}
        action={logout}
        actionLabel={t('account.logout')}
      />
    </Wrapper>
  );
}
